import { CONSTANTS } from 'analytics'
import debug from 'debug'

const { ANON_ID, USER_ID } = CONSTANTS

const logger = debug('remoteStorage:syncValues:app')

/**
 * Automatically sync values across domains
 */
export default function syncValuesPlugin(pluginConfig = {}) {
  if (!pluginConfig.remoteStorage) {
    throw new Error('Must supply remoteStorage instance')
  }

  const remoteStorage = pluginConfig.remoteStorage

  return {
    name: 'sync-remote-values',
    identify: async ({ payload }) => {
      const { traits } = payload
      const userId = traits.sub || payload.userId
      const anonId = traits['custom:anonId'] || payload.anonymousId

      /*
      // debug values
      logger('identify traits.sub', traits.sub)
      logger('identify payload.userId', payload.userId)
      logger('identify traits[custom:anonId]', traits['custom:anonId'])
      logger('identify payload.anonymousId', payload.anonymousId)
      /** */

      /* Set remote userID */
      remoteStorage.setItem({
        key: USER_ID,
        value: JSON.stringify(userId),
        resolve: remoteValueResolver,
      })
      /* Set remote anonId */
      remoteStorage.setItem({
        key: ANON_ID,
        value: JSON.stringify(anonId),
        resolve: remoteValueResolver,
      })
    },
  }
}

/**
 * Resolve potential conflicts of remote values
 */
function remoteValueResolver(data) {
  logger('remoteValueResolver data', data)
  const { key, local, remote } = data
  logger(`remoteStorage.setItem local "${key}"`, local)
  logger(`remoteStorage.setItem remote "${key}"`, remote)
  /* Local & Remote are equal */
  if (local === remote) {
    logger('Same no update')
    return
  }
  /* Local & Remote are different */
  if (local !== remote) {
    logger(`${key} has changed from ${local} to ${remote}`)
    // @TODO report ID change
  }

  /* Return local value to update remote with local value */
  logger(`Set ${key} ${local} on remote`)
  return local
}
