import Card from 'src/components/containers/card'

export type IllustrationProps = {
  children: React.ReactNode
  className?: string
  title?: string
}

const Illustration = ({ children, className, title }: IllustrationProps) => (
  <div className='h-full flex-grow bg-slate-200 lg:grid lg:grid-cols-2'>
    <div className='mb-4 flex flex-col items-center justify-center bg-white p-6 shadow-md lg:hidden'>
      <img className='w-40' src='/logo.svg' alt='logo' />
      <div>
        <h1 className='mb-4 bg-white p-6 pb-0 text-center text-xl font-semibold lg:hidden'>
          Get Connected, Get Noticed, Get Results with Vendia
        </h1>
        <p>Real-time, tamper-proof and harmonized data sharing between multiple parties or systems</p>
      </div>
    </div>
    <div className='hidden flex-col justify-between bg-white lg:flex'>
      <div className='mx-auto mt-24 max-w-lg text-center'>
        <h1 className='pt-4 text-center text-4xl font-semibold'>Get Connected, Get Noticed, Get Results with Vendia</h1>
        <p className='pt-4 leading-relaxed'>
          Real-time, tamper-proof and harmonized data sharing between multiple parties or systems
        </p>
      </div>
      <div className='mx-auto flex px-6 pb-24'>
        <img src='/images/login-illustration.svg' width='1200' alt='illustration' />
      </div>
      <div className='py-6 pl-4'>
        <img className='w-40' src='/logo.svg' alt='logo' />
      </div>
    </div>
    <div className='flex items-center justify-center bg-slate-200'>
      <Card className='max-w-lg flex-grow rounded-md bg-white 2xl:max-w-2xl'>{children}</Card>
    </div>
  </div>
)

export default Illustration
