import classNames from 'clsx'
import React from 'react'

import styles from './pill.module.css'

const pillKinds = ['none', 'success', 'info', 'pending', 'warning', 'disabled']

export type PillProps = {
  children?: React.ReactNode
  kind?: (typeof pillKinds)[number]
  className?: string
  isRounded?: boolean
  text?: string
} & React.HTMLAttributes<HTMLSpanElement>

const Pill = ({ children, className, kind = 'none', text, isRounded, ...spanProps }: PillProps) => {
  const classes = classNames(
    'component-pill',
    styles.wrapper,
    className,
    styles[kind as string],
    isRounded ? styles.rounded : '',
  )

  return (
    <span {...(spanProps as any)} className={classes}>
      <span className={styles.innerContent}>{text || children}</span>
    </span>
  )
}

export const PILL_KINDS = pillKinds.reduce((a: Record<string, string>, c) => ({ ...a, [c]: c }), {})

export default Pill
