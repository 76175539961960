// @ts-strict-ignore
import { getFormData } from '@analytics/form-utils'
import React, { ForwardedRef, forwardRef } from 'react'

export type AutoFormProps = {
  component?: string
  trim?: boolean
  trimOnSubmit?: boolean
  children?: React.ReactNode
  onChange?: (event: Event, name: string, data: any, change: any, form: HTMLFormElement) => void
  onSubmit?: (event: Event, data: any) => void
  [key: string]: any
}

const AutoForm = (
  { component = 'form', trim = false, trimOnSubmit = false, children, onChange, onSubmit, ...rest }: AutoFormProps,
  ref: ForwardedRef<HTMLFormElement>,
) => {
  const _onChange = (e) => {
    const { form, name } = e.target
    const data = getFormData.getFieldData(form, name, { trim })
    const change = { [name]: data }
    onChange(e, name, data, change, form)
  }

  const _onSubmit = (e) => {
    const data = getFormData(e.target, {
      trim: trimOnSubmit || trim,
    })
    onSubmit(e, data)
  }

  return React.createElement(
    component,
    {
      ...rest,
      ref,
      onSubmit: onSubmit && _onSubmit,
      onChange: onChange && _onChange,
    },
    children,
  )
}

export default forwardRef(AutoForm)
