import Auth from '@aws-amplify/auth'
import React, { useEffect, useRef, useState } from 'react'
import { formatLeadSourceFields } from 'src/utils/analytics/plugins/enrich-hubspot'
import notify from 'src/utils/notify'

import Button from '../buttons/button'
import FieldSet from '../inputs/field-set'
import Input from '../inputs/input'
import styles from './confirm-mfa.module.css'
import Form from './form'
import MarketingFields from './marketing-fields'

export default function ConfirmMFAForm({ user, onAuthSuccess }: { user: any; onAuthSuccess: any }) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const codeInput = useRef<any>(null)

  useEffect(() => {
    if (codeInput && codeInput.current.textInput) {
      setTimeout(() => codeInput.current.focus(), 10)
    }
  }, [])

  if (!user) {
    throw new Error('missing cognitoUser')
  }

  async function handleCodeSubmit(e: SubmitEvent, data: any) {
    e.preventDefault()
    setError(null)
    const code = data.code || ''

    if (!code || code.length < 5) {
      notify.error('Code enter is incorrect')
      return
    }

    setIsLoading(true)
    try {
      await Auth.confirmSignIn(user, code, user.challengeName)
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
      // console.log('cognitoUser', cognitoUser)
      // console.log('data', data)
      // console.log('It worked... redirect')
      setIsLoading(false)

      // Successful login! Redirect to home
      onAuthSuccess({
        type: 'mfa',
        user: cognitoUser,
        identifyAttrs: formatLeadSourceFields(data),
        // trackAttrs: {},
      })
      return
    } catch (e) {
      const err = e as any
      setIsLoading(false)
      // console.log('err', err)

      if (err.code === 'CodeMismatchException') {
        setError('Incorrect Code, Please try again')
        return
      }
      // Invalid session for the user, session is expired.
      if (err.code === 'NotAuthorizedException') {
        setError('Session has timed out, Please refresh and login again.')
        return
      }

      setError(JSON.stringify(err))
    }
  }

  const heading = (
    <div style={{ marginBottom: '1rem' }}>
      2-factor authenication is enabled for this account. Please enter the code from your device.
    </div>
  )
  const renderError = error ? <div style={{ marginBottom: '1rem' }}>{error}</div> : null
  const renderInstruction = !error ? heading : null
  return (
    <div className={styles.mfaLayout}>
      {renderError}
      {renderInstruction}
      <Form onSubmit={handleCodeSubmit}>
        <FieldSet>
          <label htmlFor='code'>MFA Code</label>
          <Input ref={codeInput} type='code' name='code' placeholder='Enter the 2 factor auth code here' />
        </FieldSet>
        {/* Get remote __ref_first from site and pass through form */}
        <MarketingFields />

        <Button
          icon={isLoading ? 'refresh' : null}
          type='submit'
          iconProps={{
            isSpinning: isLoading,
            size: 15,
          }}
        >
          {isLoading ? 'Confirming...' : 'Submit 2FA code'}
        </Button>
      </Form>
    </div>
  )
}
