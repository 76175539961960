import { Organization } from '@vendia/management-api-types'
import { useContext } from 'react'
import { QueryClient, useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'

import { authContext } from '../auth/AuthContext'
import { selectedRoleState } from '../state'
import useApi from './use-api'

const QUERY_KEY = 'getOrgDetails'
const FIVE_MINUTES = 300000

export const useGetOrgDetails = ({ ...options } = {}) => {
  const { auth } = useContext(authContext)
  const selectedRoleName = useRecoilValue(selectedRoleState).name

  const api = useApi()
  return useQuery(
    [QUERY_KEY, selectedRoleName],
    async () => {
      let data = await api.getUserOrgDetails<{ getOrganization?: Organization }>()
      if (data.errors) {
        data = await api.getUserOrg()
        const members = await api.listOrgUsers()

        if (members.errors) {
          data.errors = [...(data.errors ?? []), ...members.errors]
        } else if (data.getOrganization) {
          data.getOrganization.members = members.listOrganizationUsers
        }
      }

      if (data.errors) {
        throw new Error(JSON.stringify(data.errors))
      }
      return data
    },
    {
      staleTime: FIVE_MINUTES,
      enabled: auth.authenticated && !!selectedRoleName,
      ...options,
    },
  )
}

export const refetchOrgDetails = ({ queryClient }: { queryClient: QueryClient }) =>
  queryClient.invalidateQueries(QUERY_KEY, { refetchInactive: true })
