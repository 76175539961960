import clsx from 'clsx'
import { FocusEvent, ReactElement, useState } from 'react'
import { Control, FieldValues, useController, UseFormRegister } from 'react-hook-form'
import { getValue } from 'src/utils/form'

import Icon from '../icons/icon'
import styles from './input.updated.module.css'

export type InputFocusEvent = FocusEvent<HTMLInputElement | HTMLTextAreaElement>

export type InputProps = {
  // Name and register are required for hook form
  name: string
  register: UseFormRegister<any>
  control?: Control<FieldValues>

  id?: string
  label?: string | ReactElement
  labelDescription?: string | ReactElement
  type?: string
  kind?: string
  rules?: Record<string, any>
  errors?: Record<string, any>
  className?: string
  wrapperClassName?: string
  isTextArea?: boolean
  placeholder?: string
  nestedLabel?: string
  rows?: number
  maxLength?: number
  min?: number
  autoComplete?: string
  disabled?: boolean
  leftOfInput?: ReactElement
  rightOfInput?: ReactElement
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const Input = ({
  name,
  kind,
  rules,
  register,
  control,
  errors,
  isTextArea,
  className,
  wrapperClassName,
  label,
  labelDescription,
  disabled,
  leftOfInput,
  rightOfInput,
  placeholder,
  nestedLabel,
  ...rest
}: InputProps) => {
  if (!control && nestedLabel) {
    console.error(`Nested label requires control prop to work correctly. ${name} may not work as expected.`)
  }
  if (nestedLabel && placeholder) {
    console.error(`Nested label and placeholder provided for ${name}. This may cause unexpected behavior.`)
  }

  const [isFocused, setIsFocused] = useState(false)
  const controller = control ? useController({ name, control }) : null
  const showNestedLabel = () => nestedLabel && controller?.field.value
  const error = errors ? getValue<Record<string, any>, string>(errors, [name, 'message']) : null

  const validityStyles = error ? styles.isInvalid : styles.isValid
  const wrapperClasses = clsx(
    styles.wrapper,
    kind && styles[kind],
    styles[`${kind}Wrapper`],
    validityStyles,
    'flex flex-1 flex-col',
    wrapperClassName,
  )
  const inputClasses = clsx(
    className,
    styles.input,
    kind && styles[kind],
    { [styles.isTextArea]: isTextArea },
    validityStyles,
    {
      'placeholder:text-gray-500': nestedLabel && !controller?.field.value,
      'font-semibold !text-gray-700': nestedLabel && controller?.field.value,
      '!pt-[20px]': showNestedLabel(),
      'mt-[5px]': !labelDescription,
      '!border-gray-500 !ring-1 !ring-gray-500': isFocused && !error && !disabled,
    },
    // TODO add icon support back?
  )

  const fieldMethods = register(name, rules)

  const onFocus = (e: InputFocusEvent) => {
    setIsFocused(true)
    if (rest.onFocus) rest.onFocus(e)
  }
  const onBlur = (e: InputFocusEvent) => {
    setIsFocused(false)
    if (rest.onBlur) rest.onBlur(e)
    fieldMethods.onBlur(e)
  }

  const inputElement = isTextArea ? (
    <textarea
      aria-describedby={`${name}-description`}
      {...fieldMethods}
      className={clsx(inputClasses, 'w-full, h-[inherit]')}
      disabled={disabled}
      {...rest}
      placeholder={nestedLabel ?? placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      id={name}
    />
  ) : (
    <input
      aria-describedby={`${name}-description`}
      {...fieldMethods}
      className={inputClasses}
      disabled={disabled}
      {...rest}
      placeholder={nestedLabel ?? placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      id={name}
    />
  )
  return (
    <div className={wrapperClasses}>
      {label && (
        <label htmlFor={name}>
          <div
            className={`text-sm font-semibold ${disabled ? 'text-neutral-6' : 'text-black'} ${
              labelDescription ? '' : 'mb-1'
            }`}
          >
            {label}
          </div>
        </label>
      )}
      {labelDescription && (
        <div id={`${name}-description`} className='text-neutral-8 mb-1 text-xs'>
          {labelDescription}
        </div>
      )}
      <div className='relative'>
        {showNestedLabel() && (
          <span
            className={clsx('absolute left-3 bg-transparent text-xs text-gray-500', {
              'top-1': labelDescription,
              'top-2': !labelDescription,
            })}
          >
            {nestedLabel}
          </span>
        )}
        <div className='flex w-full'>
          {leftOfInput}
          {inputElement}
          {rightOfInput}
        </div>
      </div>
      {error && (
        <div className='align-center mt-1 flex select-none text-xs'>
          <Icon name='error' size='xs' className='mr-2' />
          {error}
        </div>
      )}
    </div>
  )
}

export default Input
