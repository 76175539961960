import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import RouteWithApi, { RouteWithSubAPI } from 'src/utils/router/route-with-api'

import UniSettingsLayout, {
  asyncLoader,
  LayoutNoNavbar,
  LayoutWithNavbar,
  OrgsLayout,
  UniSideNavLayout,
} from './app.route-helpers'
import { entityViewModes as mode } from './pages/entity-browser/constants'
// Bundle login page with initial app load so there's no loader blip when user switches from signup to login
import Login from './pages/login.page'
import { PageEditDataProducts } from './pages/uni-view/lakehouse/edit-data-products.page'
import { PageLakehouseOverview } from './pages/uni-view/lakehouse/lakehouse-overview.page'
import { PageUpdateSnowflakeConnection } from './pages/uni-view/lakehouse/snowflake-connection.page'
import { UniRedirect } from './pages/uni-view/uni-redirect'
import { PageLakehouseExplorer } from './pages/uni-view/lakehouse/lakehouse-explorer.page'

// Load everything else dynamically to slim down initial bundle size and allow code splitting to be aggressive
const OrgApiCredentials = asyncLoader(lazy(() => import('./pages/org/org-api-credentials.page')))
const OrgMembers = asyncLoader(lazy(() => import('./pages/org/org-members.page')))
const OrgOverview = asyncLoader(lazy(() => import('./pages/org/org-overview.page')))
const OrgProjects = asyncLoader(lazy(() => import('./pages/org/org-projects.page')))
const OrgRoles = asyncLoader(lazy(() => import('./pages/org/org-roles.page')))
const Callback = asyncLoader(lazy(() => import('./pages/callback.page')))
const Dashboard = asyncLoader(lazy(() => import('./pages/dashboard.page')))
const DeployToVendia = asyncLoader(lazy(() => import('./pages/deploy/deploy.page')))
const DeveloperSettings = asyncLoader(lazy(() => import('./pages/developer-settings.page')))
const EntityBrowser = asyncLoader(lazy(() => import('./pages/entity-browser/entity-browser.page')))
const EntityView = asyncLoader(lazy(() => import('./pages/entity-browser/entity-view.page')))
const LoginReset = asyncLoader(lazy(() => import('./pages/login-reset.page')))
const Logout = asyncLoader(lazy(() => import('./pages/logout.page')))
const Marketplace = asyncLoader(lazy(() => import('./pages/marketplace.page')))
const NotFound = asyncLoader(lazy(() => import('./pages/404')))
const OrgApiCredentialsCreate = asyncLoader(lazy(() => import('./pages/org/org-api-credentials-create.page')))
const OrgRoleAssign = asyncLoader(lazy(() => import('./pages/org/org-role-assign.page')))
const OrgRoleDetails = asyncLoader(lazy(() => import('./pages/org/org-role-details.page')))
const OrgRoleEdit = asyncLoader(lazy(() => import('./pages/org/org-role-edit.page')))
const ServiceRequest = asyncLoader(lazy(() => import('./pages/service-request.page')))
const Settings = asyncLoader(lazy(() => import('./pages/settings/settings.page')))
const SettingsChangeEmail = asyncLoader(lazy(() => import('./pages/settings/change-email.page')))
const SettingsChangePassword = asyncLoader(lazy(() => import('./pages/settings/change-password.page')))
const SettingsSetupMFA = asyncLoader(lazy(() => import('./pages/settings/setup-mfa.page')))
const Signup = asyncLoader(lazy(() => import('./pages/signup.page')))
const UniCreate = asyncLoader(lazy(() => import('./pages/uni-create/uni-create.page')))
const UniList = asyncLoader(lazy(() => import('./pages/uni-list/uni-list.page')))
const UniJoin = asyncLoader(lazy(() => import('./pages/uni-join.page')))
const TransactionView = asyncLoader(lazy(() => import('./pages/transaction-view.page')))
const Analyze = asyncLoader(lazy(() => import('./pages/uni-view/analyze.page')))
const DataImporter = asyncLoader(lazy(() => import('./pages/data-importer/data-importer.page')))
const EvolveSchema = asyncLoader(lazy(() => import('./pages/uni-view/schema/flow-evolve-schema/evolve-schema.page')))
const FileView = asyncLoader(lazy(() => import('./pages/file-view/file-view.page')))
const Files = asyncLoader(lazy(() => import('./pages/files/files.page')))
const RevopsFlow = asyncLoader(lazy(() => import('./pages/revops-create/revops-flow.page')))
const RevopsView = asyncLoader(lazy(() => import('./pages/revops-view/revops-view.page')))
const UniExplorer = asyncLoader(lazy(() => import('./pages/uni-explorer/uni-explorer.page')))
const UniShare = asyncLoader(lazy(() => import('./pages/uni-view/share.page')))
const WarehouseExplorer = asyncLoader(lazy(() => import('./pages/warehouse-explorer.page')))
const WorkflowsIframePage = asyncLoader(lazy(() => import('./pages/workflows/iframe.page')))
const WorkflowsPage = asyncLoader(lazy(() => import('./pages/workflows/workflows.page')))
const SmartContractDetails = asyncLoader(lazy(() => import('src/pages/smart-contracts/smart-contract-details.page')))
const SmartContracts = asyncLoader(lazy(() => import('src/pages/smart-contracts/smart-contracts.page')))
const UpsertSmartContract = asyncLoader(lazy(() => import('src/pages/smart-contracts/upsert-smart-contract.page')))
const Schema = asyncLoader(lazy(() => import('src/pages/uni-view/schema/schema.page')))
const AuthSettings = asyncLoader(lazy(() => import('./pages/uni-settings/auth-settings.page')))
const NodeResources = asyncLoader(lazy(() => import('./pages/uni-settings/node-resources.page')))
const NodeSettings = asyncLoader(lazy(() => import('./pages/uni-settings/node-settings.page')))
const ManageNode = asyncLoader(lazy(() => import('./pages/uni-settings/notifications.page')))
const SharingPolicies = asyncLoader(lazy(() => import('./pages/uni-settings/sharing-policies.page')))
const UniSpecificSettings = asyncLoader(lazy(() => import('./pages/uni-settings/uni-specific-settings.page')))

type AppProps = {
  isOrgLoaded?: boolean
  auth?: {
    authenticated?: boolean
    emailVerified?: boolean
  }
}

export default function AppRoutes({ isOrgLoaded, auth = {} }: AppProps) {
  const { authenticated, emailVerified } = auth

  if (!authenticated) {
    return (
      <Routes>
        <Route element={<LayoutNoNavbar />}>
          <Route path='/' element={<Signup />} />
          <Route path='/login/reset' element={<LoginReset appProps={auth} />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/callback' element={<Callback />} />
          <Route path='*' element={<Login />} />
        </Route>
      </Routes>
    )
  }

  /*
    Force user to verify email to continue to any other screen
    We also check if the org is loaded to avoid a flash of the verify email screen
    for SSO users whose UserAttributes.email_verified remains set to false forever
    (See corresponding band-aid in App.tsx where we set emailVerifed to true for SSO users)
  */
  if (authenticated && isOrgLoaded && emailVerified === false) {
    return (
      <Routes>
        <Route element={<LayoutWithNavbar />}>
          <Route path='*' element={<SettingsChangeEmail auth={auth} />} />
        </Route>
      </Routes>
    )
  }

  /* Authenicated Routes  */
  return (
    <Routes>
      <Route element={<LayoutWithNavbar />}>
        <Route path='/' element={<Dashboard />} />
        <Route path='/developer' element={<DeveloperSettings />} />
        <Route path='/login' element={<Navigate to='/' />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/deploy' element={<DeployToVendia />} />
        {/* Temp service request */}
        <Route path='/service-request' element={<ServiceRequest />} />
        {/* Settings*/}
        <Route path='/settings' element={<Settings />} />
        <Route path='/settings/password' element={<SettingsChangePassword />} />
        <Route path='/settings/email' element={<SettingsChangeEmail auth={auth} />} />
        <Route path='/settings/mfa' element={<SettingsSetupMFA />} />
        {/* Organization */}
        <Route path='/org' element={<OrgsLayout />}>
          <Route path='overview' element={<OrgOverview />} />
          <Route path='members' element={<OrgMembers />} />
          <Route path='roles' element={<OrgRoles />} />
          <Route path='projects' element={<OrgProjects />} />
          <Route path='api-credentials' element={<OrgApiCredentials />} />
        </Route>
        <Route path='/org/roles/create' element={<OrgRoleEdit />} />
        <Route path='/org/roles/:roleId' element={<OrgRoleDetails />} />
        <Route path='/org/roles/:roleId/edit' element={<OrgRoleEdit />} />
        <Route path='/org/roles/:roleId/assign' element={<OrgRoleAssign />} />
        <Route path='/org/api-credentials/create' element={<OrgApiCredentialsCreate />} />
        {/* AWS Marketplace*/}
        <Route path='/marketplace' element={<Marketplace />} />
        {/* Uni Routes */}
        <Route path='/uni' element={<UniList />} />
        <Route path='/uni/create' element={<UniCreate />} />
        {/* Uni Routes w/ UniSideNav */}
        <Route path='/uni/:id' element={<UniSideNavLayout />}>
          <Route path=':node'>
            <Route path='analyze' element={<RouteWithApi component={Analyze} />} />
            <Route
              path='blocks/:blockId/transactions/:transactionId'
              element={<RouteWithApi component={TransactionView} />}
            />
            <Route path='share' element={<RouteWithApi component={UniShare} />} />
            <Route path='workflows' element={<RouteWithApi component={WorkflowsPage} />} />
            <Route path='entities' element={<RouteWithSubAPI component={EntityBrowser} />} />
            <Route path='entities/:entity'>
              <Route path='' element={<RouteWithSubAPI component={EntityBrowser} />} />
              <Route path='create' element={<RouteWithSubAPI component={EntityView} mode={mode.CREATE} />} />
              <Route path='edit' element={<RouteWithSubAPI component={EntityView} mode={mode.EDIT} />} />
              <Route path=':entityId' element={<RouteWithSubAPI component={EntityView} mode={mode.READ} />} />
              <Route path=':entityId/edit' element={<RouteWithSubAPI component={EntityView} mode={mode.EDIT} />} />
            </Route>
            <Route path='files' element={<RouteWithSubAPI component={Files} />} />
            <Route path='files/:fileId' element={<RouteWithSubAPI component={FileView} />} />
            <Route path='warehouse-explorer' element={<RouteWithSubAPI component={WarehouseExplorer} />} />
            <Route path='revops' element={<RouteWithApi component={RevopsView} />} />
            <Route path='revops-setup' element={<RouteWithApi component={RevopsFlow} />} />
            <Route path='' element={<Navigate to='entities' />} />
          </Route>
        </Route>
        {/* Uni Routes without UniSideNav */}
        <Route path='/uni/:id'>
          <Route path='join' element={<UniJoin />} />
          <Route path=':node'>
            <Route path='settings/schema/evolve' element={<EvolveSchema />} />
            <Route path='settings' element={<UniSettingsLayout />}>
              <Route path='uni-settings' element={<UniSpecificSettings />} />
              <Route path='resources' element={<RouteWithApi component={NodeResources} />} />
              <Route path='success-notifications' element={<RouteWithApi component={ManageNode} />} />
              <Route path='error-notifications' element={<RouteWithApi component={ManageNode} />} />
              <Route path='node-settings' element={<RouteWithApi component={NodeSettings} />} />
              <Route path='auth-settings' element={<RouteWithApi component={AuthSettings} />} />
              <Route path='sharing-policies' element={<RouteWithApi component={SharingPolicies} />} />
              <Route path='schema' element={<RouteWithApi component={Schema} />} />
              <Route path='smart-contracts'>
                <Route path='' element={<RouteWithSubAPI component={SmartContracts} />} />
                <Route path='create' element={<RouteWithSubAPI component={UpsertSmartContract} />} />
                <Route path=':smartContractId' element={<RouteWithSubAPI component={SmartContractDetails} />} />
                <Route path=':smartContractId/edit' element={<RouteWithSubAPI component={UpsertSmartContract} />} />
                <Route
                  path=':smartContractId/:smartContractVersionOrdinal'
                  element={<RouteWithSubAPI component={SmartContractDetails} />}
                />
              </Route>
              {/* Redirect to /resources */}
              <Route path='' element={<Navigate to='resources' />} />
            </Route>
            <Route path='data-importer' element={<RouteWithSubAPI component={DataImporter} />} />
            <Route path='explorer' element={<RouteWithSubAPI component={UniExplorer} />} />
            <Route path='workflows'>
              <Route path='recipes/:recipeId/*' element={<RouteWithApi component={WorkflowsIframePage} />} />
              <Route path='connections/:connectionId/*' element={<RouteWithApi component={WorkflowsIframePage} />} />
            </Route>
          </Route>
          {/* Data Distro Uni Routes */}
          <Route path={`distro-dashboard`} element={<PageLakehouseOverview />} />
          <Route path={`distro-dashboard/explore-product-data`} element={<PageLakehouseExplorer />} />
          <Route path={`edit-products`} element={<PageEditDataProducts />} />
          <Route path={`edit-connection`} element={<PageUpdateSnowflakeConnection />} />
          {/* Handle anything that lands directly on the /uni/some-uni route (redirect to data distro, etc) */}
          <Route path='' element={<UniRedirect />} />
        </Route>
      </Route>

      {/* ----- ENTITY EXPLORER ALTERNATE DEMO "APP" ROUTES ------ */}
      <Route element={<LayoutNoNavbar />}>
        <Route path='/uni/:id/:node/app' element={<RouteWithSubAPI component={EntityBrowser} appMode='demo' />} />
        <Route
          path='/uni/:id/:node/app/:entity'
          element={<RouteWithSubAPI component={EntityBrowser} appMode='demo' />}
        />
        <Route
          path='/uni/:id/:node/app/:entity/:entityId'
          element={<RouteWithSubAPI component={EntityView} mode={mode.READ} appMode='demo' />}
        />
        <Route
          path='/uni/:id/:node/app/:entity/edit' // For top-level objects, not part of an array
          element={<RouteWithSubAPI component={EntityView} mode={mode.EDIT} appMode='demo' />}
        />
        <Route
          path='/uni/:id/:node/app/:entity/:entityId/edit' // Edit item in an array
          element={<RouteWithSubAPI component={EntityView} mode={mode.EDIT} appMode='demo' />}
        />
        <Route
          path='/uni/:id/:node/app/:entity/create' // Create new item in an array
          element={<RouteWithSubAPI component={EntityView} mode={mode.CREATE} appMode='demo' />}
        />
      </Route>

      {/* Redirects */}
      <Route path='/.well-known/change-password' element={<Navigate to='/settings/password' />} />

      {/* Finally, catch all unmatched routes */}
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
