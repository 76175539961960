import clsx from 'clsx'
import { ComponentProps } from 'react'

import Icon from '../icons/icon'

interface ButtonIconActionProps extends ComponentProps<'button'> {
  label: string
  iconSize?: string | number
  color?: string
  icon: string
}

export const ButtonIconAction = ({
  label,
  icon,
  color = 'black',
  iconSize = 20,
  className,
  ...rest
}: ButtonIconActionProps) => {
  return (
    <button
      className={clsx(
        'size-9', // May want to make this controllable by a prop
        'bg-uibg-2 hover:bg-uibg-3 flex items-center justify-center rounded-full transition-colors duration-300',
        `!${color}`,
        className,
      )}
      aria-label={label}
      {...rest}
    >
      <Icon name={icon} size={iconSize} />
    </button>
  )
}
