import { RemoteStorage } from '@analytics/remote-storage-utils'

import config from '../../../config'

const { isBrowser, isProd } = config
const remoteUrl = isProd ? 'https://www.vendia.com/storage.html' : 'http://localhost:3003/storage.html'

/* initialize remote storage ASAP */
const remoteStorage = isBrowser ? new RemoteStorage(remoteUrl, { timeout: 3000 }) : null

export default remoteStorage
