import { useController } from 'react-hook-form'

import Icon from '../icons/icon'
import MonacoEditor from './monaco-editor'

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(' ')
}

interface MonacoEditorInputProps {
  /**
   * The unique name of the field to represent in the form
   */
  name: string
  /**
   * The default language to use for Monaco (eg. json, graphql, javascript)
   */
  language: string
  /**
   * React Hook Form: Control
   * eg. const { control } = useForm(formOptions)
   */
  control: any
  /**
   * React Hook Form: Rules
   * eg. cont rules = { required: 'This field is required' }
   */
  rules: Record<string, any>
  defaultValue?: string
  className?: string
  id?: string
  minHeight?: number
}

/**
 * A react-hook-form connected component for MonacoEditor
 */
export const MonacoEditorInput = ({
  defaultValue,
  name,
  language,
  control,
  rules,
  className,
  ...rest
}: MonacoEditorInputProps) => {
  const { field, fieldState } = useController({ name, control, rules, defaultValue })
  const { value, onChange } = field
  const { error } = fieldState
  const handleChange = (val: any) => {
    onChange(val)
  }
  return (
    <>
      <MonacoEditor
        className={classNames(
          error
            ? 'border-error-8 focus:border-error-5 focus:ring-error-5'
            : 'border-black focus:border-gray-500 focus:ring-gray-500',
          // NOTE: Used to have max width here to prevent resize bug, but seems ok now...
          'rounded-md border p-1 py-6',
          className,
        )}
        onChange={handleChange}
        src={value}
        language={language}
        readOnly={false}
        {...rest}
      />
      {error && (
        <div className='flex gap-1 py-1 text-xs'>
          <Icon name='error' size='xs' className='' />
          {error.message}
        </div>
      )}
    </>
  )
}

export default MonacoEditorInput
