// @ts-strict-ignore
import { ActionEnum } from '@vendia/management-api-types'
import { useLocation } from 'react-router'
import OrgLogoBadge from 'src/components/messages/org-logo-badge'
import PageHeader from 'src/components/page-header'
import { useGetOrg } from 'src/utils/hooks/use-get-org'
import useRolePermits from 'src/utils/hooks/use-role-permits'

import OrgInvite from './org-invite'

interface OrgHeaderProps {
  showInviteMember?: boolean
}

export default function OrgHeader({ showInviteMember = true }: OrgHeaderProps) {
  const { data } = useGetOrg()
  const orgName = data?.getOrganization?.name
  const orgLogo = data?.getOrganization?.logo
  const canInvite = useRolePermits({ action: ActionEnum.OrgAll })
  const location = useLocation()
  const showInviteButton = canInvite && ['/org/overview', '/org/members'].includes(location.pathname)
  return (
    <PageHeader
      title={orgName}
      image={orgLogo ? <OrgLogoBadge src={orgLogo} size={56} /> : null}
      actions={showInviteButton && showInviteMember && <OrgInvite />}
    />
  )
}
