import { Uni } from '@vendia/management-api-types'
import { Navigate, useParams } from 'react-router-dom'
import PageLoader from 'src/components/loaders/page-loader'
import { ShareAppName } from 'src/types/types'
import { useGetShareApps } from 'src/utils/hooks/use-get-share-apps'
import { useGetUni } from 'src/utils/hooks/use-get-uni'

export const UniRedirect = () => {
  const { id: uniName } = useParams()
  const getUniQuery = useGetUni()
  const shareAppsQuery = useGetShareApps({ uniName })
  const uni = getUniQuery?.data?.getUni as Uni | undefined
  const nodes = uni?.nodes || []
  const nodeZeroName = nodes?.[0]?.name

  const isLakehouseUni = shareAppsQuery?.data?.some(
    (shareApp) =>
      shareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion ||
      shareApp.shareAppName === 'lakehouse-cloudera-ingestion',
  )

  if (!uni || !uniName || !shareAppsQuery.data) {
    return (
      <div className='grid place-items-center'>
        <PageLoader />
      </div>
    )
  }

  if (isLakehouseUni) {
    return <Navigate to={`/uni/${uniName}/distro-dashboard`} />
  }

  {
    /* NOTE: in the new navigation paradigm, we almost never land on the uni/:id route (without a /:node path) for
    regular unis. We flatten it and every page is a child of the uni/:id/:node/ route. But, just in case we missed
    something and landed here, we'll redirect to the first node's entities page. */
  }
  return <Navigate to={`/uni/${uniName}/${nodeZeroName}/entities`} />
}
