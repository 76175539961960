// @ts-strict-ignore
import { useEffect } from 'react'

const isInsideRef = (ref: any, event: any) => {
  if (ref.current && ref.current.contains(event.target)) {
    return true
  }

  const coords = ref.current?.getBoundingClientRect()
  const x = event.clientX
  const y = event.clientY
  if (coords && x >= coords.left && x <= coords.right && y >= coords.top && y <= coords.bottom) {
    return true
  }
  return false
}

//https://usehooks.com/useOnClickOutside/
export default function useOnClickOutside(ref: any, handler: any) {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || isInsideRef(ref, event)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  )
}
