import { useEffect } from 'react'
import { captureException } from 'src/utils/misc/sentry'

// Note - avoid using custom UI components as many rely on providers that don't wrap this error boundary fallback

export function ErrorFallback({ error }: { error: Error }) {
  useEffect(() => {
    console.error('Fallback to error boundary page', error)
    captureException(error) // Error broken here.
  }, [error])
  return (
    <div className='min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
      <div className='mx-auto max-w-max'>
        <main className='sm:flex'>
          <p className='text-4xl font-extrabold text-black sm:text-5xl'>500</p>
          <div className='sm:ml-6'>
            <div className='sm:border-l sm:border-gray-200 sm:pl-6'>
              <h1 className='text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl'>Server Error</h1>
              <p className='mt-1 text-base text-gray-500'>Something unexpected happened 😬</p>
            </div>
            <div className='mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6'>
              <button
                className='rounded-lg bg-blue-600 px-4 py-2 text-white hover:bg-blue-500'
                onClick={() => {
                  window.location.href = '/'
                }}
              >
                Go back home
              </button>
              <button
                className='rounded-lg border px-4 py-2 hover:border-gray-200 hover:bg-gray-50'
                onClick={() => {
                  window.location.href = 'mailto:support@vendia.com'
                }}
              >
                Contact support
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
