import { ActionEnum } from '@vendia/management-api-types'
import SideNav from 'src/components/navigation/side-nav'
import useFeatureToggle, { Features } from 'src/utils/hooks/use-feature-toggle'
import { useGetOrgDetails } from 'src/utils/hooks/use-get-org-details'
import useRolePermits from 'src/utils/hooks/use-role-permits'

export default function OrgNav() {
  const canListOrgUsers = useRolePermits({ action: ActionEnum.OrgListUsers })
  const getOrgDetails = useGetOrgDetails()
  const canListOrgUnis = Boolean(getOrgDetails?.data?.getOrganization?.unis?.length)
  const canListRoles = useRolePermits({ action: ActionEnum.RoleGet })
  const { isEnabled: isApiCredentialsEnabled } = useFeatureToggle(Features.API_CREDENTIALS)
  const canListCredentialsPermission = useRolePermits({ action: ActionEnum.ApicredsGet })
  const canListCredentials = isApiCredentialsEnabled && canListCredentialsPermission
  const links = [
    {
      name: 'Org overview',
      path: 'overview',
    },
  ]

  if (canListOrgUsers) {
    links.push({
      name: 'Members',
      path: 'members',
    })
  }

  if (canListRoles) {
    links.push({
      name: 'Roles',
      path: 'roles',
    })
  }

  if (canListOrgUnis) {
    links.push({
      name: 'Projects',
      path: 'projects',
    })
  }

  if (canListCredentials) {
    links.push({
      name: 'API Credentials',
      path: 'api-credentials',
    })
  }

  return <SideNav basePath={`/org/`} links={links} /* backLink={{name: 'Back to Uni dashboard', path: '..'}}  */ />
}
