export const AZURE_REGIONS = [
  { value: 'eastus', display: 'Virginia' },
  { value: 'westus2', display: 'Washington' },
  { value: 'northeurope', display: 'Ireland' },
  { value: 'germanywestcentral', display: 'Frankfurt' },
] as const

export const AWS_REGIONS = [
  { value: 'us-west-2', display: 'Oregon', displayAlt: 'US West (Oregon)' },
  { value: 'us-east-1', display: 'N. Virginia', displayAlt: 'US East (N. Virginia)' },
  { value: 'us-east-2', display: 'Ohio', displayAlt: 'US East (Ohio)' },
  { value: 'eu-west-1', display: 'Ireland', displayAlt: 'EU West (Ireland)' },
  { value: 'eu-central-1', display: 'Frankfurt', displayAlt: 'EU Central (Frankfurt)' },
] as const

export const SIMPLE_AWS_REGIONS = [
  { value: 'eu-central-1', display: 'Germany' },
  { value: 'eu-west-1', display: 'Ireland' },
  { value: 'us-west-2', display: 'United States' },
] as const

const AWS_AZURE_MAPPING = [
  ['us-east-1', 'eastus'],
  ['us-west-2', 'westus2'],
  ['eu-central-1', 'germanywestcentral'],
  ['eu-west-1', 'northeurope'],
] as const

export const findAwsRegionForAzureRegion = (azureRegion?: string) =>
  AWS_AZURE_MAPPING.find((mapping) => mapping[1] === azureRegion)?.[0]

export const findAwsRegionName = (awsRegion?: string) =>
  AWS_REGIONS.find((region) => region.value === awsRegion)?.display
