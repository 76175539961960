// @ts-strict-ignore
// If the role is custom, we are able to do a lot of validation here:
// 1. Validate that this is actually JSON!
// 2. Validate the format against a schema. We should catch invalid actions, invalid resources, etc.
// 3. TODO: Validate that the current user has access to make this update
export const validateCapabilities = (customRoleCapabilities: string) => {
  try {
    JSON.parse(customRoleCapabilities)
  } catch (e) {
    throw new Error(`Syntax Error: Invalid JSON — ${e.message}`)
  }

  // @ts-ignore when monaco loads, it creates a global on window
  const currentMarkers = window?.monaco?.editor?.getModelMarkers()
  if (currentMarkers?.length > 0) {
    throw new Error(`Invalid input on line ${currentMarkers.at(0).startLineNumber}`)
  }
}
