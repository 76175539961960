import { DataPolicyColumnEffect, DataPolicyOperator, SnowflakeTableColumnInput } from '@vendia/management-api-types'
import clsx from 'clsx'
import debug from 'debug'
import { useCallback, useMemo } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { LakehouseEditStepValues } from 'src/types/lakehouse'
import {
  dataProductOperatorOptions,
  getColumnPermissionOptions,
  getColumnOptions,
  rowPermissionOptions,
} from 'src/utils/lakehouse/flows'
import { getCurrentProductFieldValue, setCurrentProductFieldValue } from 'src/utils/lakehouse/products'

import Button from '../buttons/button'
import Card from '../containers/card'
import { StepComponent, StepComponentProps } from '../flows/multi-step-flow'
import Icon from '../icons/icon'
import Input from '../inputs/input.hookform'
import ListboxInput, { ListOption } from '../inputs/listbox-input'

const logger = debug('app:sharingPermissions')

const calcWidthForSelect = (options: SnowflakeTableColumnInput[]) => {
  const longestOption = options
    .map((o) => o.name)
    .sort((a, b) => (a.length >= b.length ? 1 : -1))
    .pop()

  const len = longestOption?.length ?? 20
  return { minWidth: `${len + 5}ch`, width: `${len + 5}ch` }
}

type ColumnConfigProps = StepComponentProps<LakehouseEditStepValues> & {
  defaultColumnPolicyEffect?: DataPolicyColumnEffect
  snowflakeColumnOptions: ListOption[]
}

const ColumnConfig = ({
  control,
  trigger,
  register,
  stepValues,
  formState: { errors },
  defaultColumnPolicyEffect,
}: ColumnConfigProps) => {
  const productIndex = stepValues.sharingPermissionsEditIndex
  const name = `products.${productIndex}.columnPolicies`
  const { fields, remove, append } = useFieldArray({ name, control, rules: { minLength: 1 } })
  const columnPolicies = useWatch({ name, control })

  /*  @ts-ignore formstate.errors is strangely typed */
  const columnPolicyErrors = errors?.products?.[productIndex]?.columnPolicies
  const columnPermissionOptions = getColumnPermissionOptions(defaultColumnPolicyEffect)
  const snowflakeColumnOptions = getColumnOptions(stepValues.selectedTableColumns, columnPolicies)

  async function addColumnPolicy() {
    await trigger(name)
    if (!columnPolicyErrors) {
      append({ name: '', effect: '', value: '' }, { shouldFocus: true, focusName: 'name' })
    }
  }

  return (
    <div className='flex w-full flex-1 flex-col'>
      {fields.length === 0 && (
        <h1 className='m-2 self-center text-2xl'>{`All columns will be ${defaultColumnPolicyEffect === DataPolicyColumnEffect.Allow ? 'shared' : 'excluded'}`}</h1>
      )}
      {fields.length > 0 && (
        <div
          className={clsx(
            'mb-4 flex h-5/6 w-full max-w-7xl flex-col justify-center gap-3',
            // h.scroll entire rows area on smaller screens
            'min-w-[1100px]',
          )}
        >
          {fields.map((field, index) => {
            const fieldPrefix = `${name}.${index}`
            const requireConstraint =
              columnPolicies?.[index]?.effect &&
              [DataPolicyColumnEffect.MaskExceptLast, DataPolicyColumnEffect.Replace].includes(
                columnPolicies[index].effect,
              )

            return (
              <div
                key={field.id}
                className={'bg-uibg-1 rounded-md border border-indigo-300 p-4'}
                data-testid={`column-sharing-policy-${index}`}
              >
                <div className='flex items-center justify-stretch gap-4'>
                  <div className='flex w-full items-start justify-items-stretch gap-4'>
                    <span className='text-neutral-8 self-center'>When</span>
                    <ListboxInput
                      nestedLabel='Attribute'
                      name={`${fieldPrefix}.name`}
                      listOptions={snowflakeColumnOptions ?? []}
                      rules={{ required: 'This field is required' }}
                      control={control}
                      data-testid={`row-policy-attribute-select-${index}`}
                      style={calcWidthForSelect(stepValues.selectedTableColumns)}
                    />
                    <span className='text-neutral-8 self-center'>is present,</span>
                    <ListboxInput
                      name={`${fieldPrefix}.effect`}
                      nestedLabel='Action'
                      listOptions={columnPermissionOptions}
                      rules={{ required: 'This field is required' }}
                      control={control}
                      data-testid={`row-policy-action-select-${index}`}
                      className='min-w-80'
                    />
                    {requireConstraint && (
                      <>
                        <Input
                          name={`${fieldPrefix}.value`}
                          register={register}
                          control={control}
                          nestedLabel={
                            columnPolicies?.[index]?.effect === DataPolicyColumnEffect.MaskExceptLast
                              ? 'Constraint'
                              : 'Replacement'
                          }
                          rules={{
                            required: 'This field is required.',
                            validate: columnPolicies?.[index]?.effect === DataPolicyColumnEffect.MaskExceptLast && {
                              mustBeNumber: (v: string) => !Number.isNaN(parseInt(v)) || 'Must be a number',
                            },
                          }}
                          errors={errors}
                          data-testid={`row-policy-value-input-${index}`}
                          className='mt-1 min-w-20 grow'
                        />
                        {columnPolicies?.[index]?.effect === DataPolicyColumnEffect.MaskExceptLast && (
                          <span className='text-neutral-8 self-center'>characters</span>
                        )}
                      </>
                    )}
                  </div>
                  <div className={'px-2'}>
                    <Button kind='link' icon='trash' onClick={() => remove(index)} />
                  </div>
                </div>
                {columnPolicyErrors?.[index]?.message && (
                  <div className='flex gap-1 text-balance py-1 text-left text-xs'>
                    <Icon name='error' size='xs' />
                    {columnPolicyErrors?.[index]?.message}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
      <div className='flex w-full max-w-4xl pb-6'>
        <Button
          kind={'secondary'}
          onClick={(e) => {
            e.preventDefault()
            addColumnPolicy()
          }}
          icon='plus-m'
          iconSize={14}
        >
          {fields.length ? 'Add another' : 'Add column policy'}
        </Button>
      </div>
    </div>
  )
}

type RowConfigProps = StepComponentProps<LakehouseEditStepValues> & {
  snowflakeColumnOptions: ListOption[]
}

const RowConfig = ({ control, register, formState: { errors }, stepValues, trigger }: RowConfigProps) => {
  const productIndex = stepValues.sharingPermissionsEditIndex
  const name = `products.${productIndex}.rowPolicies`
  const { fields, remove, append } = useFieldArray({ name, control })
  const rowPolicies = useWatch({ name, control })

  /*  @ts-ignore formstate.errors is strangely typed */
  const rowPolicyErrors = errors?.products?.[productIndex]?.rowPolicies
  const snowflakeColumnOptions = getColumnOptions(stepValues.selectedTableColumns, rowPolicies)

  async function addRowPolicy() {
    await trigger(name)
    if (!rowPolicyErrors) {
      append({ name: '', effect: '', operator: '', value: '' }, { shouldFocus: true, focusName: 'effect' })
    }
  }

  return (
    <div className='flex w-full flex-1 flex-col'>
      {fields.length === 0 && <h1 className='m-2 self-center text-2xl'>No rows will be filtered</h1>}
      {fields.length > 0 && (
        <div
          className={clsx(
            'mb-4 flex h-5/6 w-full max-w-7xl flex-col justify-center gap-4',
            // h.scroll entire rows area on smaller screens
            'min-w-[1100px]',
          )}
        >
          {fields.map((field, index) => {
            const fieldPrefix = `${name}.${index}`
            const policy = rowPolicies?.[index]
            console.log('row policy', index, field, policy)

            const requireConstraint =
              rowPolicies?.[index]?.operator &&
              ![DataPolicyOperator.IsNotNull, DataPolicyOperator.IsNull].includes(rowPolicies[index].operator)

            return (
              <div
                key={field.id}
                className={'bg-uibg-1 rounded-md border border-indigo-300 p-4'}
                data-testid={`row-sharing-policy-${index}`}
              >
                <div className='flex items-center justify-stretch gap-3'>
                  <div className='flex w-full items-start justify-items-stretch gap-4'>
                    <ListboxInput
                      name={`${fieldPrefix}.effect`}
                      nestedLabel='Action'
                      listOptions={rowPermissionOptions}
                      rules={{ required: 'This field is required' }}
                      control={control}
                      data-testid={`row-policy-action-select-${index}`}
                      className='min-w-40'
                    />
                    <span className='text-neutral-8 self-center'>data, where</span>
                    <ListboxInput
                      name={`${fieldPrefix}.name`}
                      nestedLabel='Attribute'
                      listOptions={snowflakeColumnOptions}
                      rules={{ required: 'This field is required' }}
                      control={control}
                      data-testid={`row-policy-attribute-select-${index}`}
                      style={calcWidthForSelect(stepValues.selectedTableColumns)}
                    />
                    <span className='text-neutral-8 self-center'>is</span>
                    <ListboxInput
                      name={`${fieldPrefix}.operator`}
                      nestedLabel='Operator'
                      listOptions={dataProductOperatorOptions}
                      rules={{ required: 'This field is required' }}
                      control={control}
                      data-testid={`row-policy-operator-select-${index}`}
                      className='min-w-[13rem]'
                    />
                    {requireConstraint && (
                      <Input
                        name={`${fieldPrefix}.value`}
                        register={register}
                        control={control}
                        nestedLabel='Constraint'
                        rules={{ required: 'This field is required.' }}
                        errors={rowPolicyErrors}
                        data-testid={`row-policy-value-input-${index}`}
                        className='mt-1 min-w-20 grow'
                      />
                    )}
                  </div>
                  <div className={'px-2'}>
                    <Button kind='link' icon='trash' onClick={() => remove(index)} />
                  </div>
                </div>
                {rowPolicyErrors?.[index]?.message && (
                  <div className='flex gap-1 text-balance py-1 text-left text-xs'>
                    <Icon name='error' size='xs' />
                    {rowPolicyErrors?.[index]?.message}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}

      <div className='flex w-full max-w-4xl pb-6'>
        <Button
          kind={'secondary'}
          onClick={(e) => {
            e.preventDefault()
            addRowPolicy()
          }}
          icon='plus-m'
          iconSize={14}
        >
          {fields.length > 0 ? 'Add another' : 'Add row policy'}
        </Button>
      </div>
    </div>
  )
}

export const EditSharingPermissions: StepComponent<LakehouseEditStepValues> = (props) => {
  const toggleColumnDefault = useCallback(() => {
    const field = 'defaultColumnPolicyEffect'
    const current = getCurrentProductFieldValue(props.stepValues, field)
    const newValue =
      current === DataPolicyColumnEffect.Allow ? DataPolicyColumnEffect.Exclude : DataPolicyColumnEffect.Allow

    props.setStepValues(setCurrentProductFieldValue(props.stepValues, field, newValue))
  }, [props.stepValues])

  const snowflakeColumnOptions = getColumnOptions(props.stepValues.selectedTableColumns, [])
  const defaultColumnPolicyEffect = getCurrentProductFieldValue<DataPolicyColumnEffect>(
    props.stepValues,
    'defaultColumnPolicyEffect',
  )

  return (
    <div className='flex w-full flex-col items-center justify-center py-4'>
      <Card
        overflow='visible'
        className='text-neutral-8 mb-4 w-full max-w-7xl flex-1 flex-col'
        title={<span className='text-base font-semibold'>Column permissions</span>}
        actions={
          <Button kind='toggle' onClick={toggleColumnDefault}>
            Default to&nbsp;<strong>{defaultColumnPolicyEffect?.toLowerCase()}</strong>&nbsp;all columns
          </Button>
        }
        data-testid='column-permissions-card'
      >
        <ColumnConfig
          {...props}
          defaultColumnPolicyEffect={defaultColumnPolicyEffect}
          snowflakeColumnOptions={snowflakeColumnOptions}
        />
      </Card>

      <Card
        overflow='visible'
        className='text-neutral-8 w-full max-w-7xl flex-1 flex-col'
        title={<span className='text-base font-semibold'>Row permissions</span>}
        data-testid='row-permissions-card'
      >
        <RowConfig {...props} snowflakeColumnOptions={snowflakeColumnOptions} />
      </Card>
    </div>
  )
}
