import 'notyf/notyf.min.css'
import 'graphiql/graphiql.css'
import '@graphiql/plugin-explorer/dist/style.css'
import './global.css'

import Auth from '@aws-amplify/auth'
import ReactDOM from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import App from './app.root'
import { ErrorFallback } from './components/containers/error-fallback'
import { enableMocking } from './mock/enable-mocking'
import UploadFileStatusProvider from './pages/files/upload-file-status-provider'
import AuthContextProvider from './utils/auth/AuthContext'
import { getLoggedInNode, getLoggedInNodeAmplifyConfig } from './utils/auth/logged-in-node'

const signedInNode = getLoggedInNode()
const amplifyConfig = getLoggedInNodeAmplifyConfig({ signedInNode })

Auth.configure(amplifyConfig)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
})

function initReact() {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <BrowserRouter>
              <UploadFileStatusProvider>
                <App />
              </UploadFileStatusProvider>
            </BrowserRouter>
          </AuthContextProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </ErrorBoundary>,
  )
}

enableMocking().then(initReact)
