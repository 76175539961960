// @ts-strict-ignore
/*
 Urql Client for calling into Appsync
*/
import { devtoolsExchange } from '@urql/devtools'
import { authExchange } from '@urql/exchange-auth'
import { retryExchange } from '@urql/exchange-retry'
import debug from 'debug'
import { createClient as createWebsocketClient } from 'graphql-ws'
import { getStoredUserAuth, isTokenExpired } from 'src/utils/auth/AuthContext'
import { refreshSession } from 'src/utils/auth/cognito'
import { createClient, fetchExchange, makeOperation, subscriptionExchange } from 'urql'

import { captureException } from '../sentry'

const logger = debug('app:urql')

function getToken() {
  const session = getStoredUserAuth()
  return session.idToken
}

async function refreshToken() {
  return refreshSession()
}

export async function subClient({ websocketApi, selectedRoleName }) {
  if (!websocketApi) {
    return
  }

  const subscriptionClient = createWebsocketClient({
    url: websocketApi,
    connectionParams: async () => {
      logger('subscriptionClient.connectionParams')
      try {
        const token = getToken()
        if (isTokenExpired(token)) {
          try {
            logger('subscriptionClient.connectionParams: refreshing token')
            const newSession = await refreshToken()

            const newToken = newSession.idToken
            logger('subscriptionClient.connectionParams: returning refreshed token')
            return {
              token: newToken,
              selected_role_name: selectedRoleName,
            }
          } catch (e) {
            captureException(e)
            console.warn('Unable to refesh token. Logging out', e)
          }
          window.location.href = `${window.location.origin}/logout`
          return null
        }
        logger('subscriptionClient.connectionParams: returning token')
        return {
          token,
          // Send along selected role for data plane RBAC support (along with roles which are encoded in JWT via userService preTokenGeneration Lambda)
          selected_role_name: selectedRoleName,
        }
      } catch (e) {
        captureException(e)
        console.error('Error attempting to get JWT token for socket connection', e?.message)
      }
    },
  })

  return subscriptionClient
}

export const makeUrqlFetchClient = async ({ graphqlApi, graphqlApiKey, websocketApi, route, selectedRoleName }) => {
  const subscriptionClient = await subClient({ websocketApi, selectedRoleName })
  return createClient({
    url: graphqlApi,
    exchanges: [
      devtoolsExchange,
      authExchange(async () => {
        const token = getToken()
        return {
          refreshAuth: async () => {
            logger('refreshAuth')
            try {
              const newSession = await refreshToken()
              const newToken = newSession.idToken
              logger('refreshAuth: new token', newToken)
            } catch (e) {
              captureException(e)
              console.warn('Unable to refesh token. Logging out', e)
              window.location.href = `${window.location.origin}/logout`
            }
          },
          addAuthToOperation: (operation) => {
            const token = getToken()
            if (!token) {
              logger('addAuthToOperation: No token, returning operation')
              return operation
            }

            // fetchOptions can be a function (See Client API) but you can simplify this based on usage
            const fetchOptions =
              typeof operation.context.fetchOptions === 'function'
                ? operation.context.fetchOptions()
                : operation.context.fetchOptions || {}
            return makeOperation(operation.kind, operation, {
              ...operation.context,
              fetchOptions: {
                ...fetchOptions,
                headers: {
                  ...fetchOptions.headers,
                  'x-api-key': graphqlApiKey,
                  Authorization: `Bearer ${token}`,
                  'x-vendia-client': 'app',
                  'x-vendia-feature': route,
                  'x-vendia-role-name': selectedRoleName,
                },
              },
            })
          },
          willAuthError: () => {
            if (isTokenExpired(token)) {
              logger('willAuthError: Token is expired, refreshing...')
              return true
            }
            return false
          },
          didAuthError: (error) => {
            logger('didAuthError: Server returned an error response', error?.response?.status)
            return error?.response?.status === 403
          },
        }
      }),
      retryExchange({
        initialDelayMs: 200,
        randomDelay: true,
        maxNumberAttempts: 10,
        maxDelayMs: 15000,
        retryIf: (error) => {
          return !!error.networkError && error?.response?.status !== 403
        },
      }),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription(request) {
          const input = { ...request, query: request.query || '' }
          return {
            subscribe: (sink) => {
              const unsubscribe = subscriptionClient.subscribe(input, sink)
              return {
                unsubscribe,
              }
            },
          }
        },
      }),
    ],
  })
}
