// @ts-strict-ignore
import classNames from 'clsx'
import React from 'react'

export default function FormContainer({ children, ...props }) {
  const classes = classNames(props.className)
  return (
    <div {...props} className={classes}>
      {children}
    </div>
  )
}
