import classNames from 'clsx'
import React from 'react'

import styles from './field-set.module.css'

export type FieldSetProps = {
  /** Custom CSS Classes */
  className?: string
  /** Alignment of label + input */
  align?: 'horizontal' | 'vertical'
  /** Style of  Label Container */
  labelStyle?: Record<string, any>
  /** Style of Input Container */
  inputStyle?: Record<string, any>
  children: React.ReactNode[]
}

/**
 * Used to group `Label` and `Input` components. Must have exactly 2 children
 */
const FieldSet = ({ children, className, align = 'vertical', labelStyle, inputStyle, ...other }: FieldSetProps) => {
  const classes = classNames(
    'component-fieldSet',
    styles.fieldSet,
    className,
    styles[align],
    // propBasedClasses
  )

  if (!children || children.length !== 2) {
    throw new Error('FieldSet component must have exactly 2 children')
  }

  return (
    <div {...other} className={classes}>
      <div className={styles.labelWrapper} style={labelStyle}>
        {children[0]}
      </div>
      <div className={styles.inputWrapper} style={inputStyle}>
        {children[1]}
      </div>
    </div>
  )
}

export default FieldSet
