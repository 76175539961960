// @ts-strict-ignore
import { Organization } from '@vendia/management-api-types'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import inputValidation from 'src/components/inputs/input.validation'
import useApi from 'src/utils/hooks/use-api'
import { refetchOrg } from 'src/utils/hooks/use-get-org'
import notify from 'src/utils/notify'

import Button from '../buttons/button'
import DevOnly from '../dev/dev-only'
import Icon from '../icons/icon'
import Input from '../inputs/input.hookform'
import BasicModal from '../modals/modal.basic'
import { logger, ModalState, OrgModalInner, OrgOnboardingStep } from './org-onboarding-modal'

export function StepOrgName({
  org,
  setModalState,
  dismissModal,
}: {
  org?: Organization
  setModalState: (state: Partial<ModalState>) => void
  dismissModal: () => void
}) {
  const api = useApi()
  const queryClient = useQueryClient()

  const defaultValues = {
    name: org?.name,
  }

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: 'onChange', delayError: 1000, shouldFocusError: true, defaultValues })

  interface Response {
    updateOrgProfile: Organization
    errors: {
      message: string
    }[]
  }

  const mutation = useMutation<Response, Error, typeof defaultValues>(async ({ name }) =>
    api.updateOrgProfile({ name }),
  )

  const onSubmit = async (data) => {
    logger('data', data)

    const response = await mutation.mutateAsync(data)
    if (response?.errors?.length) {
      notify.error(response.errors[0].message)
      return
    }
    setModalState({ step: OrgOnboardingStep.SET_LOGO })

    // Refresh org
    await new Promise((resolve) => setTimeout(resolve, 1000))
    refetchOrg({ queryClient })
  }

  return (
    <>
      <OrgModalInner>
        <form id='edit-org-name' onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-8 flex flex-col gap-12'>
            <p>First, let's give your Organization a name. Typically, this will be the name of your company.</p>
            <div className='j flex w-full justify-center gap-4'>
              <Input
                className='!w-96'
                label={'Organization name'}
                register={register}
                name='name'
                placeholder='Awesome Company, Inc.'
                errors={errors}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: inputValidation.isGenericNonEmptyString.pattern,
                    message: inputValidation.isGenericNonEmptyString.message,
                  },
                }}
              />
            </div>
          </div>
        </form>
      </OrgModalInner>
      <BasicModal.Footer>
        <div className='flex gap-2'>
          <Button kind='secondary' size={'small'} className='w-36' onClick={dismissModal}>
            Cancel
          </Button>
          <DevOnly>
            <Button
              kind='secondary'
              size={'small'}
              className='w-36'
              onClick={() => setModalState({ step: OrgOnboardingStep.INTRO })}
            >
              Previous
            </Button>
          </DevOnly>
          <Button
            kind='primary'
            size={'small'}
            className='w-36'
            disabled={!isValid || mutation.isLoading}
            type='submit'
            form='edit-org-name'
          >
            {mutation.isLoading && <Icon className={'mr-2'} isSpinning name='refresh' size={'xs'} />}
            Next
          </Button>
        </div>
      </BasicModal.Footer>
    </>
  )
}
