import React from 'react'
import { useParams } from 'react-router-dom'
import PageLoader from 'src/components/loaders/page-loader'
import { ProvideUniApi, ProvideUniApiProps, useNodeApi } from 'src/utils/api-provider'
import { PendingTransactionsProvider } from 'src/utils/pending-transactions'

export type ComponentWithAPIProps = ProvideUniApiProps & {
  component: any
  sku?: string
  mode?: string
  appMode?: string
}

export default function ComponentWithAPI(props: ComponentWithAPIProps) {
  const { id, node } = useParams() as any
  // Default sku is SHARE so we don't have to pass it in every route
  const mergedProps = { ...props, sku: 'SHARE' }
  return (
    <ProvideUniApi {...mergedProps} uniName={id} nodeName={node}>
      <WaitForApi {...mergedProps} />
    </ProvideUniApi>
  )
}

export const RouteWithSubAPI = (props: ComponentWithAPIProps) => {
  const { id, node } = useParams() as any
  // Default sku is SHARE so we don't have to pass it in every route
  const mergedProps = { ...props, sku: 'SHARE' }
  return (
    <ProvideUniApi {...mergedProps} uniName={id} nodeName={node}>
      <PendingTransactionsProvider>
        <WaitForApi {...mergedProps} />
      </PendingTransactionsProvider>
    </ProvideUniApi>
  )
}

function WaitForApi({ sku, component: C, ...props }: ComponentWithAPIProps) {
  const { getUniQ } = useNodeApi()
  return getUniQ?.isLoading ? (
    <div className='grid place-items-center'>
      <PageLoader />
    </div>
  ) : (
    <C sku={sku} {...props} />
  )
}
