import React, { useEffect, useRef, useState } from 'react'

import AnimatedEllipsis from './animated-ellipsis'
import styles from './loader.module.css'
import ProgressBar from './progress-bar'

const progressSteps = [
  {
    value: 2,
    message: 'Starting deployment',
    delay: 5000,
  },
  {
    value: 5,
    message: 'Initializing stack',
    delay: 10000,
  },
  {
    value: 10,
    message: 'Configuring resources',
    delay: 10000,
  },
  {
    value: 15,
    message: 'Configuring API settings',
    delay: 10000,
  },
  {
    value: 20,
    message: 'Provisioning backend infrastructure',
    delay: 50000,
  },
  {
    value: 25,
    message: 'Provisioning GraphQL API from data schema',
    delay: 15000,
  },
  {
    value: 30,
    message: 'Verifying backend infrastructure',
    delay: 10000,
  },
  {
    value: 35,
    message: 'Waiting on deployment',
    delay: 50000,
  },
  {
    value: 45,
    message: 'Waiting on deployment artifacts',
    delay: 30000,
  },
  {
    value: 55,
    message: 'Securing resources',
    delay: 30000,
  },
  {
    value: 65,
    message: 'Checking on deploy',
    delay: 30000,
  },
  {
    value: 70,
    message: 'Verifying GraphQL API',
    delay: 10000,
  },
  {
    value: 74,
    message: 'Verifying GraphQL Subscriptions',
    delay: 10000,
  },
  {
    value: 79,
    message: 'Validating File Storage API',
    delay: 10000,
  },
  {
    value: 82,
    message: 'Verifying smart contracts',
    delay: 10000,
  },
  {
    value: 85,
    message: 'Checking on deploy',
    delay: 30000,
  },
  {
    value: 90,
    message: 'Verifying ledger',
    delay: 15000,
  },
  {
    value: 95,
    message: 'Finishing up',
    delay: 15000,
  },
  {
    value: 95,
    message: 'Finishing up',
    delay: 15000,
  },
  {
    value: 99,
    message: 'Wrapping up',
    delay: 5000,
  },
]

/*
const totalCount = progressSteps.reduce((acc, curr) => {
  acc = curr.delay + acc
  return acc
}, 0)
console.log('debug total time', totalCount)
console.log('debug minutes', totalCount / 1000 / 60)
/** */

function ensureMax(num: number, max = 100) {
  return num > max ? max : num
}

function rand(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export default function FakeLoader({ isFinished = false, onComplete }: { isFinished?: boolean; onComplete?: any }) {
  const timer = useRef<any>()
  const tweenTimer = useRef<any>()
  const [progress, setProgress] = useState(-1)
  const [message, setMessage] = useState(progressSteps[0].message)
  const [value, setValue] = useState(0)
  const [waitForComplete, setWaitForComplete] = useState(false)

  async function stepThroughMessages() {
    let index = 0
    let id
    let delay = progressSteps[0].delay
    setProgress((p) => {
      const newValue = p + 1
      index = newValue
      const msg = progressSteps[newValue] && progressSteps[newValue].message
      const val = progressSteps[newValue] && progressSteps[newValue].value
      delay = progressSteps[newValue] && progressSteps[newValue].delay
      if (msg) {
        setMessage(msg)
      }
      if (val) {
        setValue(ensureMax(val))
      }
      return newValue
    })
    /* Recurse through messages */
    if (index < progressSteps.length && delay) {
      const nextId = index + 1
      const currentValue = progressSteps[index] && progressSteps[index].value
      const nextValue = progressSteps[nextId] && progressSteps[nextId].value
      // console.log('currentValue', currentValue)
      // console.log('nextValue', nextValue)
      window.clearTimeout(tweenTimer.current)
      if (nextValue) {
        const diff = nextValue - currentValue
        const random = rand(1, 4)
        const timeout = random * 1000
        // console.log('timeout', timeout)
        const valueToIncrement = (diff / delay) * timeout
        // console.log('diff', diff)
        // console.log('valueToIncrement', valueToIncrement)
        tweenTimer.current = setInterval(() => {
          setValue((v) => {
            const tweenVal = ensureMax(v + valueToIncrement)
            // console.log('tweenVal', tweenVal)
            return tweenVal
          })
        }, timeout)
      }
      id = setTimeout(stepThroughMessages, delay)
    } else {
      setWaitForComplete(true)
      window.clearTimeout(tweenTimer.current)
    }
    return id
  }

  useEffect(() => {
    timer.current = stepThroughMessages()
    return () => {
      window.clearTimeout(timer.current)
      window.clearTimeout(tweenTimer.current)
    }
  }, [])

  useEffect(() => {
    if (waitForComplete && onComplete) {
      window.clearTimeout(timer.current)
      window.clearTimeout(tweenTimer.current)
      onComplete({
        actions: {
          complete: (msg: string) => {
            setMessage(msg || 'Done')
            setValue(100)
          },
        },
      })
    }
  }, [waitForComplete])

  const isDone = value === 100
  const loadingDots = isDone ? null : <AnimatedEllipsis />
  return (
    <div>
      <div className={styles.messageWrapper}>
        <div className={styles.message}>
          {message}
          {loadingDots}
        </div>
        <div>{value.toFixed(2)}%</div>
      </div>
      <ProgressBar value={value} isDone={isDone} />
    </div>
  )
}
