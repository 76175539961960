import { ATTR_FIRST, ATTR_LATEST, ATTR_PREVIOUS, getAttribution } from '@vendia/analytics-attribution'

import dotProp from '../../misc/dot-prop'
import remoteStorage from './remote-storage'

let refCache = {}
export async function getRefData(key) {
  if (refCache._type) {
    return key ? dotProp(refCache, key) : refCache
  }
  const localData = getAttribution() || {}
  const remoteValues = (await getRemoteData([ATTR_FIRST, ATTR_LATEST, ATTR_PREVIOUS])) || { first: {} }
  const remoteData = Object.keys(remoteValues).reduce((acc, curr) => {
    const newKey = curr.replace(/^__ref_/, '')
    acc[`${newKey}`] = remoteValues[curr] || {}
    return acc
  }, {})
  const isRemoteOlder = getTime(localData?.first?.date) > getTime(remoteData?.first?.date)
  // console.log('localData', localData)
  // console.log('remoteData', remoteData)
  // console.log('isRemoteOlder', isRemoteOlder)
  const info = {
    _type: isRemoteOlder ? 'remote' : 'local',
    _first: isRemoteOlder ? remoteData.first : localData.first,
    local: localData,
    remote: remoteData,
  }
  // Set cache
  refCache = info
  return key ? dotProp(info, key) : info
}

export async function getRemoteData(key) {
  if (!remoteStorage) return
  /* Grab remote value */
  return remoteStorage.getItem(key)
}

function getTime(t) {
  return t ? new Date(t).getTime() : Infinity
}
