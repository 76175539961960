import { UpdateSnowflakeStepValues } from 'src/types/lakehouse'

import { StepComponent } from '../flows/multi-step-flow'
import Input from '../inputs/input.hookform'
import ExternalLink from '../navigation/external-links'

export const SnowflakeConnection: StepComponent<UpdateSnowflakeStepValues> = (props) => {
  const {
    register,
    control,
    formState: { errors },
  } = props
  return (
    <div className='w-full max-w-4xl'>
      <div className='flex w-full flex-col gap-4'>
        <Input
          label='Snowflake Account Identifier'
          labelDescription={
            <span>
              The account identifier is the first part of your Snowflake account&apos;s URL, eg.
              &lt;account-identifier&gt;.snowflakecomputing.com.{' '}
              <ExternalLink
                className=''
                iconSize='xxs'
                href='https://docs.snowflake.com/en/user-guide/admin-account-identifier'
              >
                Learn more
              </ExternalLink>
            </span>
          }
          className='!w-max flex-1 !rounded-none !border-l-0 !border-r-0'
          leftOfInput={
            <div className='border-neutral-9 bg-uibg-1 select-none truncate rounded-md rounded-r-none border p-2'>
              https://
            </div>
          }
          rightOfInput={
            <div className='border-neutral-9 bg-uibg-1 select-none truncate rounded-md rounded-l-none border p-2'>
              .snowflakecomputing.com
            </div>
          }
          name='snowflakeHost'
          type='text'
          placeholder='ABC123.us-east-1 or ORG123.ABC123'
          register={register}
          control={control}
          rules={{ required: 'This field is required' }}
          errors={errors}
        />
        <Input
          nestedLabel='Warehouse'
          labelDescription='The warehouse that should be used to execute queries'
          name='snowflakeWarehouse'
          type='text'
          register={register}
          control={control}
          rules={{ required: 'This field is required' }}
          errors={errors}
        />
        <Input
          nestedLabel='Database'
          labelDescription='The database containing the tables you want to share'
          name='snowflakeDatabase'
          type='text'
          register={register}
          control={control}
          rules={{ required: 'This field is required' }}
          errors={errors}
        />
        <Input
          nestedLabel='Username'
          labelDescription='The username for the Snowflake account you want to connect with'
          name='snowflakeUsername'
          type='text'
          register={register}
          control={control}
          rules={{ required: 'This field is required' }}
          errors={errors}
        />
        <Input
          nestedLabel='Password'
          labelDescription='The password for the Snowflake account you want to connect with'
          name='snowflakePassword'
          type='password'
          register={register}
          control={control}
          rules={{ required: 'This field is required' }}
          errors={errors}
        />
        <Input
          nestedLabel='Schema (Optional)'
          labelDescription='The schema where your tables are located. "PUBLIC" schema will be used by default'
          name='snowflakeSchema'
          type='text'
          register={register}
          control={control}
          errors={errors}
        />

        <Input
          nestedLabel='Role (Optional)'
          labelDescription="The name of the role to use when connecting to Snowflake. If not provided, the user's default role will be used."
          name='snowflakeRole'
          type='text'
          register={register}
          control={control}
          errors={errors}
        />
      </div>
    </div>
  )
}
