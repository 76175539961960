// @ts-strict-ignore
import _config from 'src/config'

export const getProvidersForDomain = async (domain) => {
  const { providers } = await (
    await fetch(_config.domainApi, {
      method: 'POST',
      body: JSON.stringify({
        domain,
      }),
    })
  ).json()

  return providers
}
