// @ts-strict-ignore
import React, { useEffect, useRef } from 'react'

import AutoForm from './auto-form'

function fixScroll() {
  // http://bit.ly/2gQ6jFJ
  this.scrollIntoView(false)
}

const grabNodes = (node) => node?.querySelectorAll('input,select,textarea') ?? []

export type FormProps = {
  id?: string
  onChange?: (event: Event, data: any) => void
  className?: string
  formClassName?: string
  trimOnSubmit?: boolean
  children?: React.ReactNode
  name?: string
  [key: string]: any
}

const Form = ({ id, onChange, className, formClassName, trimOnSubmit, children, name, ...rest }: FormProps) => {
  const formRef = useRef<HTMLElement>(null)

  useEffect(() => {
    const inputNodes = grabNodes(formRef.current)
    inputNodes.forEach((node) => {
      node.addEventListener('invalid', fixScroll)
    })
    return () => {
      inputNodes.forEach((node) => {
        node.removeEventListener('invalid', fixScroll)
      })
    }
  }, [formRef])

  const handleSubmit = (event, data) => {
    const { onSubmit } = rest
    event.preventDefault()
    if (onSubmit) {
      onSubmit(event, data)
    }
  }

  return (
    <div className={className}>
      <AutoForm
        name={name}
        id={id}
        trimOnSubmit={trimOnSubmit}
        onChange={onChange}
        onSubmit={handleSubmit}
        className={formClassName}
        {...rest}
      >
        {children}
      </AutoForm>
    </div>
  )
}

export default Form
