// @ts-strict-ignore
import { getNameResourceFromResourceString, NameResourceRepresentation } from '@vendia/management-api-rbac'
import { ActionEnum, Capability, Maybe } from '@vendia/management-api-types'
import clsx from 'clsx'
import Icon from 'src/components/icons/icon'
import { ResourceRepresentation } from 'src/types/types'

// Create a Capability type that adds some extra properties for seeing what's been added/removed
type DiffStatus = 'ADDED' | 'REMOVED' | 'UNCHANGED'
type CapabilityDiffProperties = {
  actionDiffStatus: DiffStatus
  addedResources: ResourceRepresentation[]
  removedResources: ResourceRepresentation[]
}
type DiffCapability = Capability & CapabilityDiffProperties

export type NewAndOldDiffCapabilities = {
  new: DiffCapability[]
  old: DiffCapability[]
}

type ReadOnlyRoleOverviewProps = {
  // Email not passed in for Org invite role previews
  email?: Maybe<string>
  capabilities: Capability[] | DiffCapability[]
}

interface RoleDiffViewProps {
  email?: string
  oldCapabilities?: Capability[]
  newCapabilities?: Capability[]
  newTemplateName?: string
}

export const RoleDiffView = ({ email, oldCapabilities, newCapabilities, newTemplateName }: RoleDiffViewProps) => {
  const sortedNewCapabilities = dedupeAndSortCapabilities(newCapabilities)
  const sortedOldCapabilities = dedupeAndSortCapabilities(oldCapabilities)
  const { old: oldCapabilitiesWithDiffProperties, new: newCapabilitiesWithDiffProperties } =
    addDiffPropertiesToOldAndNewCapabilities({
      oldCapabilities: sortedOldCapabilities,
      newCapabilities: sortedNewCapabilities,
    })
  return (
    <div className='grid grid-cols-2 gap-x-4 p-2'>
      <div>
        <div className='mb-3'>Current</div>
        <ReadOnlyRoleOverview email={email} capabilities={oldCapabilitiesWithDiffProperties} />
      </div>
      <div>
        <div className='mb-3'>New ({newTemplateName})</div>
        <ReadOnlyRoleOverview email={email} capabilities={newCapabilitiesWithDiffProperties} />
      </div>
    </div>
  )
}

export const ReadOnlyRoleOverview = ({ email, capabilities }: ReadOnlyRoleOverviewProps) => {
  const sortedCapabilities = dedupeAndSortCapabilities(capabilities)
  return (
    <div>
      <ul className='flex flex-col gap-2'>
        {sortedCapabilities.map((capability: Capability | DiffCapability) => {
          const { action, resources } = capability
          let actionDiffStatus: DiffStatus = 'UNCHANGED'
          let addedResources: string[] = []
          let removedResources: string[] = []

          if ('actionDiffStatus' in capability) {
            actionDiffStatus = capability.actionDiffStatus
            addedResources = capability.addedResources
            removedResources = capability.removedResources
          }

          const actionClasses = clsx({
            'text-success-9': actionDiffStatus === 'ADDED',
            'text-red-700': actionDiffStatus === 'REMOVED',
          })
          return (
            <li key={action}>
              <h4 className={`flex items-center capitalize ${actionClasses}`}>
                <div className='w-8'>
                  {actionDiffStatus === undefined && <Icon name='check' size='xs' stroke='gray' />}
                  {actionDiffStatus === 'ADDED' && (
                    <Icon className='ml-3' name='plus-m' size='xxs' stroke='rgb(11 123 0)' />
                  )}
                  {actionDiffStatus === 'REMOVED' && <Icon className='ml-3' name='minus' size='xxs' stroke='red' />}
                </div>
                {action.toLocaleLowerCase().split('_').join(' ')}
              </h4>
              <div className='whitespace-pre-wrap break-words pl-[2px] text-xs text-gray-600'>
                {resources.map((resource: string, i: number) => {
                  // Apply some formatting or return null if we don't want to show it
                  const displayText = getResourceDisplayText(email!, action, resource)
                  if (!displayText) {
                    return null
                  }
                  let classes = ''
                  const isRemoved = actionDiffStatus === 'REMOVED' || removedResources?.includes(resource)
                  const isAdded = actionDiffStatus === 'ADDED' || addedResources?.includes(resource)
                  classes = clsx({
                    'flex items-center text-black': true,
                    'text-success-9': isAdded,
                    'text-red-700': isRemoved,
                  })
                  return (
                    <div key={`${capability.action}${resource}${i}`} className={classes}>
                      <div className='w-8 min-w-[2rem]'>
                        {isAdded && <Icon className='ml-5' name='plus-m' size={6} stroke='rgb(11 123 0)' />}
                        {isRemoved && <Icon className='ml-5' name='minus' size={6} stroke='red' />}
                      </div>
                      {displayText}
                    </div>
                  )
                })}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export function dedupeAndSortCapabilities<T extends Capability[] | DiffCapability[]>(capabilities?: T): T {
  const dedupedCapabilities = [] as DiffCapability[]
  const seenActions = new Set<ActionEnum>()
  capabilities?.forEach((capability: any) => {
    if (!seenActions.has(capability.action)) {
      seenActions.add(capability.action)
      dedupedCapabilities.push(capability)
    } else {
      const existingCapability = dedupedCapabilities.find((c) => c.action === capability.action)
      if (existingCapability) {
        // Note: not deduping resources here - if they have the same resource twice, fine to show it twice
        // Just want to make sure we only show each ACTION category once in the list
        existingCapability.resources = [...existingCapability.resources, ...capability.resources]
      }
    }
    // Sort the resources for each action
    capability.resources.sort((a: string, b: string) => {
      // Put NameResources before UniResources
      if (a.startsWith('NameResource') && !b.startsWith('NameResource')) {
        return -1
      }
      if (!a.startsWith('NameResource') && b.startsWith('NameResource')) {
        return 1
      }
      // Put specific NameResources before wildcard NameResources
      if (a.startsWith('NameResource(*') && !b.startsWith('NameResource(*')) {
        return 1
      }
      if (!a.startsWith('NameResource(*') && b.startsWith('NameResource(*')) {
        return -1
      }
      return a < b ? -1 : 1
    })
  })
  // Sort by action, grouping ORG actions first, then UNI actions, then USER actions
  return dedupedCapabilities.sort((a, b) => {
    if (a.action.startsWith('ORG_') && !b.action.startsWith('ORG_')) {
      return -1
    }
    if (!a.action.startsWith('ORG_') && b.action.startsWith('ORG_')) {
      return 1
    }
    if (a.action.startsWith('UNI_') && !b.action.startsWith('UNI_')) {
      return -1
    }
    if (!a.action.startsWith('UNI_') && b.action.startsWith('UNI_')) {
      return 1
    }
    return a.action < b.action ? -1 : 1
  }) as T
}

// Get the stuff between parens, e.g. 'NameResource(stuff)' -> 'stuff'
function getResourceParenContents(resource: string): string {
  const parenContents = resource.match(/\(([^()]+)\)/g)
  if (Array.isArray(parenContents) && typeof parenContents[0] === 'string') {
    return (
      parenContents[0]
        // Remove parens
        .replace('(', '')
        .replace(')', '')
    )
  }
  return ''
}

function getResourceDisplayText(email: string, action: ActionEnum, resource: string): string | null {
  if (resource) {
    if (resource === 'VendiaResource(owned)') {
      if (action.startsWith('UNI_')) {
        return 'Owned Unis'
      }
    }

    // ---------------------------------------- NAME RESOURCE ----------------------------------------
    if (resource.startsWith('NameResource(')) {
      const parts = getNameResourceFromResourceString(resource as NameResourceRepresentation)

      // If it's the default "you can invite any email address" permission, don't show it
      if (
        action === ActionEnum.UserInvite &&
        parts.name === '*' &&
        parts.domain === '*' &&
        parts.subdomain === '*' &&
        parts.ext === '*'
      ) {
        return null
      }

      // We won't have email address if this is a preview for an Org invite
      // We should get an empty NameResource() from out role templates code in this case...
      if (!email && resource === 'NameResource()' && action.startsWith('UNI_')) {
        return 'Owned Unis'
      }

      // Otherwise if somehow the case above doesn't match and we don't have an email, show nothing
      if (!email) {
        return null
      }

      const [name, fullDomain] = email.split('@')
      // COULD have subdomain, e.g. 'foo.bar.com', so grab last two parts
      const domainParts = fullDomain.split('.')
      const ext = domainParts.pop()
      const domain = domainParts.pop()
      // If it's a name resource for ME, don't show it
      if (parts && parts.name === name && parts.domain === domain && parts.ext === ext) {
        if (action.startsWith('UNI_')) {
          return `Owned Unis`
        }
        return null
      }

      if (action.startsWith('UNI_')) {
        return `Unis created by: ${getResourceParenContents(resource)}`
      }
      if (action.startsWith('USER_')) {
        return `User emails matching: ${getResourceParenContents(resource)}`
      }
      return null

      // ---------------------------------------- UNI RESOURCE ----------------------------------------
    } else if (resource.startsWith('UniResource(')) {
      // Looks good for now, but could consider tweaks:
      //  - Show namespace if it's this Orgs only namespace?
      //  - Show the default multi-tenant namespace? Or respresent the default multi-tenant namespace as
      //    'Vendia Multi-tenant Namespace' or something?
      const namespace = getResourceParenContents(resource)
        // Don't show the node part of UniResource if it's * (all nodes)
        .replace('#*', '')
        // Format it nicely if it's a specific node
        .replace(/#(.*)/, ' (node: $1)')
      return `Uni names matching: ${namespace}`

      // ---------------------------------------- ORG RESOURCE ----------------------------------------
    } else if (resource.startsWith('OrganizationResource(')) {
      // Org resources only target your own org (for now), so don't show it
      // Otherwise we'd have to look up the name of the org...
      return null
    } else if (resource.startsWith('VendiaResource(')) {
      //TODO, look for cross-org permissions and update the text accordingly
      const [orgId, ...parts] = getResourceParenContents(resource).split('/')
      const uni = parts?.[0]
      const node = parts?.[1]
      if (uni === '*') {
        return 'Unis in your organization'
      } else if (parts.length === 2 && node === '*') {
        return `Nodes in Uni ${uni} owned by your organization`
      } else if (parts.length >= 2) {
        return `Node ${node} in Uni ${uni}`
      }
    }
  }
  return null
}

// Compare old and new capabilities and return a list of changes
export function addDiffPropertiesToOldAndNewCapabilities({
  oldCapabilities,
  newCapabilities,
}: {
  oldCapabilities: Capability[]
  newCapabilities: Capability[]
}): NewAndOldDiffCapabilities {
  const oldCapabilitiesWithDiffProperties = oldCapabilities.map((oldCapability) => {
    const newCapability = newCapabilities.find((c) => c.action === oldCapability.action)
    if (newCapability) {
      return {
        ...oldCapability,
        actionDiffStatus: 'UNCHANGED' as DiffStatus,
        addedResources: [] as ResourceRepresentation[],
        removedResources: oldCapability.resources.filter(
          (r) => !newCapability.resources.includes(r),
        ) as ResourceRepresentation[],
      }
    }
    return {
      ...oldCapability,
      actionDiffStatus: 'REMOVED' as DiffStatus,
      addedResources: [] as ResourceRepresentation[],
      removedResources: oldCapability.resources as ResourceRepresentation[],
    }
  })
  const newCapabilitiesWithDiffProperties = newCapabilities.map((newCapability) => {
    const oldCapability = oldCapabilities.find((c) => c.action === newCapability.action)
    if (oldCapability) {
      return {
        ...newCapability,
        actionDiffStatus: 'UNCHANGED' as DiffStatus,
        addedResources: newCapability.resources.filter(
          (r) => !oldCapability.resources.includes(r),
        ) as ResourceRepresentation[],
        removedResources: [],
      }
    }
    return {
      ...newCapability,
      actionDiffStatus: 'ADDED' as DiffStatus,
      addedResources: newCapability.resources as ResourceRepresentation[],
      removedResources: [],
    }
  })
  return { old: oldCapabilitiesWithDiffProperties, new: newCapabilitiesWithDiffProperties }
}
