import Editor, { DiffEditor, Monaco } from '@monaco-editor/react'
import React from 'react'

import Loader from '../loaders/loader'

// Fix window.monaco TS errors
declare global {
  interface Window {
    monaco: Monaco
  }
}

// https://github.com/suren-atoyan/monaco-react

interface MonacoEditorProps {
  /**
   * The code to render in the editor window
   */
  src?: string
  /**
   * The minimum height of the editor window
   * Default: 350
   */
  minHeight?: number

  /**
   * Add static extra height to prevent the window getting sized slightly too small (and scrolling a bit)
   * Default: 100
   */
  heightBuffer?: number

  /**
   * Disable editing
   * Default: false
   */
  readOnly?: boolean
  /**
   * @monaco-editor/react options
   */
  options?: any
  /**
   * The language for syntax highlighting
   */
  language: string
  /**
   * VSCode "theme"
   */
  theme?: string
  /**
   * VSCode "theme"
   */
  diffMode?: boolean
  // Used with diffMode
  original?: string
  // Used with diffMode
  modified?: string
  id?: string
  className?: string
  onChange?: (value: any) => void
  [key: string]: any
}
export function MonacoEditor({
  id,
  src,
  language,
  minHeight = 350,
  readOnly = false,
  diffMode = false,
  options,
  heightBuffer = 100,
  ...rest
}: MonacoEditorProps) {
  const [height, setHeight] = React.useState(minHeight)

  const EditorComponent = diffMode ? DiffEditor : Editor

  const updateHeight = (editor: any) => {
    const contentHeight = Math.max(minHeight, editor.getContentHeight())
    setHeight(contentHeight + heightBuffer)
  }
  const readOnlyProps = readOnly ? { value: src } : {}

  return (
    <div id={id} data-testid={rest['data-testid']}>
      <EditorComponent
        loading={<Loader />}
        width={'100%'}
        height={height}
        onMount={(editor: any) => {
          if (diffMode) {
            // Need to call once on mount
            const modifiedEditor = editor.getModifiedEditor()
            updateHeight(modifiedEditor)
            // And subscribe
            modifiedEditor.onDidContentSizeChange(() => {
              updateHeight(modifiedEditor)
            })
          } else {
            updateHeight(editor)
            editor.onDidContentSizeChange(() => {
              updateHeight(editor)
            })
          }
        }}
        defaultLanguage={language}
        defaultValue={src ?? ''}
        options={{
          minimap: {
            enabled: false,
          },
          scrollbar: {
            // allow parent scoll
            alwaysConsumeMouseWheel: false,
            vertical: 'hidden',
            horizontal: 'hidden',
            useShadows: false,
            verticalScrollbarSize: 8,
            horizontalScrollbarSize: 8,
            ...options?.scrollbar,
          },
          showFoldingControls: 'mouseover',
          lineNumbers: 'off',
          lineDecorationsWidth: '12px',
          scrollBeyondLastLine: false,
          readOnly,
          automaticLayout: true,
          ...options,
        }}
        {...readOnlyProps}
        {...rest}
      />
    </div>
  )
}

export default MonacoEditor
