// @ts-strict-ignore
import { Dialog } from '@headlessui/react'
import debug from 'debug'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import Button from 'src/components/buttons/button'
import Drawer from 'src/components/containers/drawer'
import Input from 'src/components/inputs/input.hookform'
import inputValidation from 'src/components/inputs/input.validation'
import RadioGroupComponent from 'src/components/inputs/radio-group'
import Tooltip from 'src/components/messages/tooltip'
import useApi from 'src/utils/hooks/use-api'
import { refetchOrg, useGetOrg } from 'src/utils/hooks/use-get-org'
import { refetchOrgDetails } from 'src/utils/hooks/use-get-org-details'
import notify from 'src/utils/notify'

import { CustomRoleBuilder } from './roles/custom-role-builder'
import { ReadOnlyRoleOverview } from './roles/read-only-role-overview'
import { RoleTemplateRadioGroup } from './roles/role-template-radio-group'
import { CUSTOM_ROLE_TEMPLATE_NAME, DEFAULT_ROLE_TEMPLATE_NAME, getRoleTemplates } from './roles/role-templates'
import { validateCapabilities } from './roles/validation-utils'

export const logger = debug('app:OrgInvite')

interface InviteResponse {
  inviteToOrg: {
    expiration: string
  }
  errors: {
    message: string
  }[]
}

const OrgInvite = () => {
  const getOrg = useGetOrg()
  const org = getOrg?.data?.getOrganization
  const hasNoApprovedDomains = org?.domains?.length === 0
  const orgDomains = org?.domains?.join(', ')
  const queryClient = useQueryClient()
  const api = useApi()
  const [showInvite, setShowInvite] = useState(false)
  const inviteMutation = useMutation<
    InviteResponse,
    Error,
    {
      inviteeEmail: string
      invitePermissionsMode: 'default' | 'custom'
      roleTemplateName: string
      customRoleCapabilities: string
    }
  >(({ inviteeEmail, roleTemplateName, customRoleCapabilities }) => {
    logger('SUBMIT', inviteeEmail, roleTemplateName)
    // Use server default role if the user selected the default role
    if (invitePermissionsMode === 'default') {
      return api.inviteToOrg({ userId: inviteeEmail, capabilities: null })
    }
    if (invitePermissionsMode === 'custom') {
      return api.inviteToOrg({ userId: inviteeEmail, capabilities: JSON.parse(customRoleCapabilities) })
    }
    // The preview role templates don't have the user's email address in the NameResources
    // Reconstruct now that we have it and match by name...
    const templatesWithInviteesEmail = getRoleTemplates({
      orgId: org!.orgId,
      orgDomains: org!.domains,
    })
    const capabilities = templatesWithInviteesEmail.find((t) => t.name === roleTemplateName)?.capabilities
    return api.inviteToOrg({ userId: inviteeEmail, capabilities })
  })

  const roleTemplates = useMemo(() => {
    if (!org?.orgId || !org?.domains) return []
    return getRoleTemplates({ orgId: org?.orgId, orgDomains: org?.domains })
  }, [org?.orgId, org?.domains])

  const formMethods = useForm({
    shouldFocusError: true,
    defaultValues: {
      inviteeEmail: '', // Need something here for typechecking
      roleTemplateName: DEFAULT_ROLE_TEMPLATE_NAME,
      invitePermissionsMode: 'default',
      customRoleCapabilities: JSON.stringify(
        [
          {
            action: 'UNI_GET',
            resources: [`UniResource(*.*.${org?.domains?.[0]})`],
          },
        ],
        null,
        2,
      ),
    },
  })
  const { handleSubmit, register, formState, reset, control, watch } = formMethods
  const selectedRoleTemplateName = watch('roleTemplateName')
  const selectedRoleTemplate = roleTemplates.find((t) => t.name === selectedRoleTemplateName)

  const invitePermissionsMode = watch('invitePermissionsMode')
  const { isDirty, errors } = formState
  const onInviteSubmit = async (formValues) => {
    logger('submit')
    if (invitePermissionsMode === 'custom') {
      if (selectedRoleTemplate?.name === CUSTOM_ROLE_TEMPLATE_NAME) {
        try {
          validateCapabilities(formValues.customRoleCapabilities)
        } catch (e) {
          console.warn('Unable to submit Org Invite', e)
          notify.error(e.message)
          return
        }
      }
    }

    try {
      const response = await inviteMutation.mutateAsync({ ...formValues })
      if (response.errors) {
        notify.error(response.errors[0].message)
        return
      }
      close()
      refetchOrg({ queryClient })
      refetchOrgDetails({ queryClient })
    } catch (e) {
      console.error('Unable to invite to org', e)
      notify.error('Oops, something went wrong. Please try again or contact support.')
      close()
    }
  }

  const close = () => {
    logger('close')
    setShowInvite(false)
  }

  const open = () => {
    logger('open')
    reset()
    setShowInvite(true)
  }

  const permissionOptions = [
    {
      value: 'default',
      label: 'Default organization role',
      description: 'User will get the default role for your organization',
    },
    {
      value: 'custom',
      label: 'Custom access level',
      description: 'User will get a new role with custom permissions',
    },
  ]

  return (
    <>
      <Button kind='secondary' icon='add' iconSize={16} onClick={open} disabled={hasNoApprovedDomains}>
        Invite org member
      </Button>
      {hasNoApprovedDomains && (
        <Tooltip className='ml-2'>
          You&rsquo;ll be able to invite members to your Organization once you have an approved domain.
        </Tooltip>
      )}
      <Drawer open={showInvite === true} onClose={() => close()} className='w-screen max-w-7xl'>
        <form onSubmit={handleSubmit(onInviteSubmit)}>
          <div className='grid h-screen grid-rows-[auto_minmax(auto,1fr)_auto]'>
            <header className='border-neutral-3 bg-neutral-0 flex items-center gap-4 border-b p-6'>
              <Dialog.Title as='span' className='text-lg font-medium leading-6 text-gray-900'>
                <h2 className='m-0 text-4xl font-semibold'>Invite to organization</h2>
              </Dialog.Title>
            </header>
            <aside className='flex min-h-0 overflow-y-auto'>
              <div className='border-neutral-3 flex min-h-0 flex-1 flex-col gap-8 overflow-y-auto border-r p-8'>
                <div className='!mb-0'>
                  <Input
                    id='inviteeEmail'
                    label={`New member's email`}
                    labelDescription='Email domain must match one of your Orgs owned domains'
                    name='inviteeEmail'
                    type='email'
                    register={register}
                    autoComplete='off'
                    rules={{
                      required: {
                        value: true,
                        message: 'This field is required.',
                      },
                      pattern: {
                        value: inputValidation.isEmail.pattern,
                        message: inputValidation.isEmail.message,
                      },
                      validate: (value) => {
                        const emailDomain = value.split('@')[1]
                        if (!org?.domains?.includes(emailDomain)) {
                          return `Email domain must match one of your Orgs owned domains (i.e., ${orgDomains}).`
                        }
                      },
                    }}
                    errors={isDirty ? errors : undefined}
                  />
                </div>

                <RadioGroupComponent
                  label='New user role'
                  labelDescription='Select the role this user will have'
                  name='invitePermissionsMode'
                  control={control}
                  // rules={{ required: 'This field is required' }}
                  options={permissionOptions}
                />

                {invitePermissionsMode === 'custom' && (
                  <RoleTemplateRadioGroup
                    label='Access level'
                    labelDescription={`Select an option below. Leave "Default Access" selected if you're not sure.`}
                    name='roleTemplateName'
                    control={control}
                  />
                )}
              </div>
              <div className='hidden min-h-0 flex-1 flex-col gap-8 overflow-y-auto p-8 sm:flex'>
                {invitePermissionsMode === 'default' && org?.defaultRole && (
                  <>
                    <p className='mb-6 font-semibold'>Default role: {org?.defaultRole?.name}</p>
                    <ReadOnlyRoleOverview capabilities={org?.defaultRole?.capabilities} />
                  </>
                )}
                {invitePermissionsMode === 'custom' && (
                  <>
                    <div className={selectedRoleTemplate?.name === CUSTOM_ROLE_TEMPLATE_NAME ? '' : 'hidden'}>
                      <CustomRoleBuilder control={control} />
                    </div>
                    {selectedRoleTemplate?.name && selectedRoleTemplate?.name !== CUSTOM_ROLE_TEMPLATE_NAME && (
                      <div className=''>
                        <p className='mb-6 font-semibold'>{selectedRoleTemplate.name} permissions</p>
                        <ReadOnlyRoleOverview capabilities={selectedRoleTemplate.capabilities} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </aside>
            <footer className='border-neutral-3 flex items-center justify-end gap-2 border-t p-6'>
              <Button kind='secondary' onClick={close}>
                Cancel
              </Button>
              <Button
                type='submit'
                kind='primary'
                disabled={formState?.isSubmitting}
                icon={formState?.isSubmitting ? 'refresh' : null}
                iconProps={{
                  isSpinning: formState?.isSubmitting,
                  size: 15,
                }}
              >
                Send invite
              </Button>
            </footer>
          </div>
        </form>
      </Drawer>
    </>
  )
}

export default OrgInvite
