import { RadioGroup } from '@headlessui/react'
import debug from 'debug'
import { Control, useController, UseControllerProps } from 'react-hook-form'
import Icon from 'src/components/icons/icon'

const logger = debug('app:RadioGroup')

type RadioGroupProps = {
  name: string
  control: Control<any>
  rules?: UseControllerProps['rules']
  label?: string | JSX.Element
  labelDescription?: string | JSX.Element
  options: {
    value: any
    label: string | JSX.Element
    description?: string | JSX.Element
  }[]
}

function RadioGroupComponent({ name, label, labelDescription, control, rules, options }: RadioGroupProps) {
  const { field, fieldState } = useController({ name, control, rules })
  const { value, onChange } = field
  const { error } = fieldState

  const handleChange = (newItem: string) => {
    logger('handle change, selected:', newItem)
    onChange(newItem)
  }

  // Prevent setting defaultValue before the form is registered
  if (value === undefined) {
    return null
  }

  return (
    <RadioGroup
      onClick={(e) => {
        // Clicks were triggering form submission
        e.preventDefault()
      }}
      defaultValue={value}
      onChange={handleChange}
      className={'flex flex-col gap-3'}
    >
      {(label || labelDescription) && (
        <div>
          {label && (
            <RadioGroup.Label className='flex flex-col gap-1'>
              <div className='text-sm font-semibold'>{label}</div>
            </RadioGroup.Label>
          )}
          {labelDescription && (
            <RadioGroup.Description className='text-neutral-8 text-xs'>{labelDescription}</RadioGroup.Description>
          )}
        </div>
      )}
      <div className={'flex flex-col gap-4'}>
        {options.map((option) => (
          <RadioGroup.Option key={JSON.stringify(option.value)} value={option.value}>
            {({ checked }) => {
              return (
                <div>
                  <RadioGroup.Label className={`flex items-center gap-2`}>
                    <div className='mr-2'>
                      {checked ? (
                        <div className='ring-information-10 h-6 w-6 rounded-full ring-8 ring-inset' />
                      ) : (
                        <div
                          className={`h-6 w-6 rounded-full ring-1 ring-inset ${
                            error ? 'ring-error-8' : 'ring-information-10'
                          }`}
                        />
                      )}
                    </div>
                    <div>
                      <div className='flex items-baseline text-sm font-semibold'>{option.label}</div>
                      {option?.description && (
                        <RadioGroup.Description className={'text-neutral-8 text-xs'}>
                          {option.description}
                        </RadioGroup.Description>
                      )}
                    </div>
                  </RadioGroup.Label>
                </div>
              )
            }}
          </RadioGroup.Option>
        ))}
      </div>
      {error && (
        <div className='flex gap-1 py-1 text-sm'>
          <Icon name='error' size='xs' className='' />
          {error.message}
        </div>
      )}
    </RadioGroup>
  )
}

export default RadioGroupComponent
