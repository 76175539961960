import Auth from '@aws-amplify/auth'
import debug from 'debug'
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDebounce } from 'react-use'
import _config from 'src/config'
import analytics, { EVENTS } from 'src/utils/analytics'
import { formatLeadSourceFields } from 'src/utils/analytics/plugins/enrich-hubspot'
import { authContext } from 'src/utils/auth/AuthContext'
import { redirectToOrgSSO, resendVerificationEmail } from 'src/utils/auth/cognito'
import { getProvidersForDomain } from 'src/utils/auth/domain-api'
import useHashParam from 'src/utils/hooks/use-hash-param'
import { getRedirectUrl } from 'src/utils/hooks/use-track-last-visited'
import useUrlSearchParams from 'src/utils/hooks/use-url-search-params'
import { captureException } from 'src/utils/misc/sentry'
import notify from 'src/utils/notify'

import Button from '../buttons/button'
import FieldSet from '../inputs/field-set'
import Input from '../inputs/input'
import ConfirmMFAForm from './confirm-mfa'
import ForceResetForm from './force-password-reset'
import Form from './form'
import FormContainer from './form-container'
import styles from './login-form.module.css'
import MarketingFields from './marketing-fields'

const logger = debug('auth:login')

export function EmailConfirmationMessage({ email }: { email: string | null }) {
  const emailText = email ? ` at ${email}` : ''
  return (
    <div>
      <h3>Email confirmation sent</h3>
      <div className={styles.introText}>
        <p>A new confirmation email has been sent to you{emailText}. Please check your inbox & spam folder.</p>
        <p>If you do not see this email, contact support.</p>
      </div>
    </div>
  )
}

export function EmailNotVerifiedMessage({
  email,
  handleResendEmail,
}: {
  email: string | null
  handleResendEmail: (email: string) => void
}) {
  return (
    <div>
      <h3>Email confirmation required</h3>
      <div className={styles.introText}>
        <p>It looks like your email has not been verified.</p>
        <p>
          To login, please verify your email address by clicking confirm link in the signup email sent to{' '}
          <strong>{email}</strong>
        </p>
        <p>
          {"If you don't see email, please check your spam folder or "}
          <strong onClick={() => handleResendEmail(email!)} style={{ color: 'blue', cursor: 'pointer' }}>
            click here to request a new verification email
          </strong>
          .
        </p>
      </div>
    </div>
  )
}

function LoginForm(props: { formTitle: React.ReactNode }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { setAuthStatus, signedInNode, unsetSignedInNodeStatus } = React.useContext(authContext)
  const [params] = useHashParam()
  const [isLoading, setIsLoading] = useState(false)
  const [isForceReset, setForceReset] = useState(false)
  const [isMfaLogin, setMfaLogin] = useState(false)
  const [needsEmailConfirmation, setNeedsEmailConfirmation] = useState<string | null>(null)
  const [emailConfirmationSent, setEmailConfirmationSent] = useState(false)
  const [cognitoUser, setCognitoUser] = useState()
  const emailInput = useRef<HTMLInputElement | null>(null)
  const passwordInput = useRef<HTMLInputElement | null>(null)
  const urlSearchParams = useUrlSearchParams()
  const [promptForPassword, setPromptForPassword] = useState(false)
  const [showSSOButton, setShowSSOButton] = useState(false)
  const [emailToCheck, setEmailToCheck] = useState('')
  const signUpUrl = useMemo(() => getSignupUrl(), [location])
  useDebounce(onEmailEnteredDebounced, 500, [emailToCheck])

  async function authSuccess({
    user,
    identifyAttrs = {},
    trackAttrs = {},
    type,
  }: {
    user: any
    identifyAttrs?: any
    trackAttrs?: any
    type: string
  }) {
    const { signInUserSession, attributes } = user
    logger('authSuccess user', user)
    logger('authSuccess location', type)

    const userId = attributes.sub
    const currentAnonId = analytics.user('anonymousId')

    // local anon id and user anonId don't match, update local value.
    if (currentAnonId && attributes['custom:anonId'] && currentAnonId !== attributes['custom:anonId']) {
      logger('authSuccess anonId change, set to', attributes['custom:anonId'])
      // @ts-ignore - setAnonymousId is not in the types
      analytics.setAnonymousId(attributes['custom:anonId'])
    }

    /* User has 'custom:anonId' defined, set it to user attribute in cognito */
    if (attributes && !attributes['custom:anonId']) {
      logger('set custom:anonId in cognito', currentAnonId)
      try {
        await Auth.updateUserAttributes(user, {
          'custom:anonId': currentAnonId,
        })
      } catch (err) {
        logger('Auth.updateUserAttributes failed', err)
      }
    }

    setAuthStatus({
      userId: attributes.sub,
      email: attributes.email,
      idToken: signInUserSession.idToken.jwtToken,
      accessToken: signInUserSession.accessToken,
      refreshToken: signInUserSession.refreshToken,
      authenticated: true,
      type: 'COGNITO',
      emailVerified: attributes.email_verified,
      contactDetails: {},
    })

    const identifyPayload = {
      ...attributes,
      ...identifyAttrs,
    }
    // Identify user with analytics tools
    analytics.identify(userId, identifyPayload)
    // Fire tracking event
    analytics.track(EVENTS.USER_SIGN_IN, {
      authType: type,
    })
    // Redirect
    if (urlSearchParams.get('redirect')) {
      navigate(location.search.split('redirect=')?.[1]) //use location.search so that encoding is kept
      return
    }
    const redirectUrl = getRedirectUrl(attributes.email) ?? '/'
    navigate(redirectUrl)
  }

  function handleRequestVerificationEmail(email: string) {
    resendVerificationEmail({
      email,
      onLoading: (_isLoading) => setIsLoading(_isLoading),
      onSuccess: (_resp) => setEmailConfirmationSent(true),
      onError: (e) => {
        console.log('e', e)
      },
    })
  }

  useEffect(() => {
    if (params.u && params.t) {
      logger('temp password & email found. Force password reset')
      setTimeout(() => {
        if (emailInput.current) {
          emailInput.current.value = window.atob(params.u)
        }
        passwordInput.current!.value = params.t
        const button: NodeListOf<HTMLButtonElement> | null = document.querySelectorAll('.login-button')
        if (button && button[0]) {
          button[0].click()
        }
      }, 10)
    } else if (params.email) {
      // Users directed here from a confirmation email link will always provide a password
      setPromptForPassword(true)
      // TODO Last pass still messing with this
      setTimeout(() => {
        if (emailInput?.current) {
          emailInput.current.value = params.email
        }
        if (passwordInput?.current) {
          passwordInput.current.value = ''
          passwordInput.current.focus()
        }
      }, 10)
    } else if (emailInput && emailInput.current) {
      emailInput.current.focus()
    }
  }, [params])

  async function checkProvidersForDomain(domain: string) {
    setIsLoading(true)

    try {
      const providers = await getProvidersForDomain(domain)

      if (providers.includes('SSO') && providers.includes('COGNITO')) {
        setShowSSOButton(true)
        setPromptForPassword(true)
      } else if (providers.includes('SSO')) {
        await redirectToOrgSSO(domain)
      } else {
        setPromptForPassword(true)
      }
    } catch (e) {
      console.error(e)
      setPromptForPassword(true)
      setShowSSOButton(false)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleEmailSubmit(e: any, data = { email: '' }) {
    e.preventDefault()
    const { email } = data

    const domain = email.split('@')[1]

    checkProvidersForDomain(domain)
  }

  async function loginWithSSO() {
    const domain = emailInput.current?.value.split('@')[1]

    if (!domain) {
      notify.error('Please enter a valid email address')
      return
    }

    await redirectToOrgSSO(domain)
  }

  const onEmailChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.validity?.valid) {
      setIsLoading(false)
    } else {
      setEmailToCheck(event?.target?.value)
    }
  }

  function onEmailEnteredDebounced() {
    if (emailToCheck) {
      checkProvidersForDomain(emailToCheck.split('@')[1])
    }
  }

  async function handleLogin(e: SubmitEvent, data = { email: '', password: '' }) {
    e.preventDefault()
    const { email, password } = data

    if (!email) {
      emailInput.current?.focus()
      return
    }

    if (!password) {
      passwordInput.current?.focus()
      return
    }

    setIsLoading(true)

    try {
      const user = await Auth.signIn(email, password)

      /* Clear all notices */
      notify.dismissAll()

      /* If MFA flow active */
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        logger('MFA login', user)
        setCognitoUser(user)
        setIsLoading(false)
        setMfaLogin(true)
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        /* If user manually created and a force reset of password is required */
        const { requiredAttributes } = user.challengeParam // eslint-disable-line
        // console.log('requiredAttributes', requiredAttributes)
        setForceReset(true)
        setCognitoUser(user)
        // ^ This will show force reset password form
      } else if (user.challengeName === 'MFA_SETUP') {
        logger('User must setup MFA')
        // If MFA is required this will be the case.
        // Auth.setupTOTP(user);
      } else {
        /* Successful login! */
        authSuccess({
          user,
          type: 'normal',
          identifyAttrs: formatLeadSourceFields(data),
        })
      }
      // // todo remove props.useHas
    } catch (e) {
      const signInError = e as any
      setIsLoading(false)

      logger('Login Error', signInError)

      // Track errors
      analytics.track(EVENTS.USER_AUTH_ERROR, {
        context: 'login',
        code: signInError.code,
      })

      // Email/password wrong
      if (signInError.code === 'NotAuthorizedException') {
        notify.error('Invalid email or password. Please try again')
        // @TODO report potential bad actor?
      }
      if (signInError.code === 'InvalidParameterException') {
        notify.error('Invalid username or password. Please try again')
      }
      // Account requires a password reset, please reset your password before attempting to login
      if (signInError.code === 'PasswordResetRequiredException') {
        // 'NewPasswordRequired'
        notify.error('Password reset required')
        // redirect to forgot/reset
      }
      // User doesnt exist yet
      if (signInError.code === 'UserNotFoundException') {
        navigate(`/signup#email=${email}&signup=true`)
        return
      }
      // User not verified in cognito yet
      if (signInError.code === 'UserNotConfirmedException') {
        // alert('Please verify your email')
        setNeedsEmailConfirmation(email)
      }
      // Cognito pools are gone 🔥
      if (signInError.code === 'ResourceNotFoundException') {
        // Send error to sentry
        captureException(signInError)
        notify.error('Something bad happened. Please contact support. Error code foxtrox')
      }
      // Offline
      if (signInError.code === 'NetworkError') {
        notify.error('Unable to login due to internet connection timeout')
      }
      // Too many requests
      if (signInError.code === 'LimitExceededException') {
        notify.error('Too many resets were requested. Please try again in an hour')
      }
    }
  }

  function handleForgotPasswordLink() {
    if (emailInput.current?.value) {
      navigate(`/login/reset/#email=${emailInput.current.value}`)
    } else {
      navigate('/login/reset/')
    }
  }

  function getSignupUrl() {
    // const currentUrl = new URL(location.pathname)
    if (!location) {
      return null
    }

    if (location.pathname === '/login') {
      return `/signup${location.search}`
    }
    const redirect = `${location.pathname}${location.search}`
    return `/signup?redirect=${redirect}`
  }

  let newUserMessage
  if (params.newUser) {
    newUserMessage = (
      <div className={styles.introText}>
        <p>Welcome to Vendia! Your email has been verified. You can now login to your new account</p>
      </div>
    )
  }
  if (params.createRedirect) {
    newUserMessage = <div className={styles.introText}>Please login to your account</div>
  }

  let resetCompleteMessage
  if (params.reset) {
    resetCompleteMessage = (
      <div className={styles.introText}>
        <b>Password successfully reset.</b> Please login below
      </div>
    )
  }

  let adminSignIn
  if (signedInNode) {
    adminSignIn = (
      <div className={styles.forgotPassword}>
        <a href='/login'>Sign in to Vendia admin</a>
      </div>
    )
  }

  let formRender = (
    <Form onSubmit={promptForPassword ? handleLogin : handleEmailSubmit}>
      <div className='flex flex-row divide-x-2'>
        <div className={`w-full ${promptForPassword ? 'pr-5' : ''}`}>
          <div>
            <FieldSet>
              <label htmlFor='email'>Email</label>
              <Input
                ref={emailInput}
                id='email'
                name='email'
                type='email'
                placeholder='Enter your email address'
                onChange={promptForPassword ? onEmailChanged : null}
                isRequired
              />
            </FieldSet>
            {promptForPassword && (
              <FieldSet>
                <div className={styles.forgotPasswordLayout}>
                  <label htmlFor='password'>Password</label>
                  <div className={styles.forgotPassword} onClick={handleForgotPasswordLink}>
                    Forgot password?
                  </div>
                </div>
                <Input
                  ref={passwordInput}
                  id='password'
                  name='password'
                  type='password'
                  placeholder='Enter your password'
                  isRequired={promptForPassword && !showSSOButton}
                />
              </FieldSet>
            )}
            {adminSignIn}
            {/* Get remote __ref_first from site and pass through form */}
            <MarketingFields />
            <div className='flex justify-between gap-2'>
              <Button
                className={`${styles.button} login-button`}
                icon={isLoading ? 'refresh' : null}
                disabled={isLoading}
                name='login-continue'
                type='submit'
                iconProps={{
                  isSpinning: isLoading,
                  size: 15,
                }}
              >
                {promptForPassword ? 'Log in' : 'Continue'}
              </Button>
            </div>
          </div>
        </div>
        {showSSOButton && (
          <div className='h-100 flex w-full items-center pl-5'>
            <Button
              className={`${styles.button} login-button`}
              icon={isLoading ? 'refresh' : 'external'}
              disabled={isLoading}
              type='button'
              kind={'secondary'}
              iconProps={{
                isSpinning: isLoading,
                size: 15,
              }}
              onClick={loginWithSSO}
            >
              Log in with SSO
            </Button>
          </div>
        )}
      </div>
    </Form>
  )

  const signUp = (
    <div>
      <hr className={styles.horizontalRule} />
      <div className={styles.signUp}>
        <p>New to Vendia Share?</p>
        {signUpUrl && (
          <Link className={styles.newAccount} to={signUpUrl} data-testid={'create-account-link'}>
            Create a new account
          </Link>
        )}
      </div>
    </div>
  )

  /* Reset email sent to user, show message */
  if (emailConfirmationSent) {
    return <EmailConfirmationMessage email={needsEmailConfirmation} />
  }

  /* UserNotConfirmedException error, show "resend email" message */
  if (Boolean(needsEmailConfirmation)) {
    return <EmailNotVerifiedMessage email={needsEmailConfirmation} handleResendEmail={handleRequestVerificationEmail} />
  }

  /* If force reset set, show password reset form */
  if (isForceReset) {
    formRender = <ForceResetForm user={cognitoUser} />
  }

  /* If MFA is set, force MFA code form */
  if (isMfaLogin) {
    formRender = <ConfirmMFAForm user={cognitoUser} onAuthSuccess={authSuccess} />
  }

  return (
    <FormContainer data-testid='form-login'>
      {props.formTitle}
      {newUserMessage}
      {resetCompleteMessage}
      {formRender}
      {signUp}
    </FormContainer>
  )
}

export default LoginForm
