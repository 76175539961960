import React from 'react'
import { getRefData } from 'src/utils/analytics/utils/get-ref-data'

import DynamicInput from '../inputs/dynamic-input'

/* Get additonal fields needed for marketing */
export default function MarketingFields() {
  return (
    <>
      <DynamicInput name='custom_first_visitor_source' getValue={() => getRefData('_first.type')} />
      <DynamicInput name='custom_first_visitor_date' getValue={() => getRefData('_first.date')} />
      <DynamicInput name='custom_first_visitor_entry' getValue={() => getRefData('_first.entry.url')} />
    </>
  )
}
