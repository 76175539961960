import { ATTR_FIRST, ATTR_LATEST, ATTR_PREVIOUS } from '@vendia/analytics-attribution'
import { CONSTANTS } from 'analytics'
import debug from 'debug'

const { ANON_ID, USER_ID, USER_TRAITS } = CONSTANTS
const logger = debug('remoteStorage:getRemoteValues')
const isBrowser = typeof window !== 'undefined'

function getPersistedCognitoInfo() {
  return Object.keys(localStorage).reduce(
    (acc, curr) => {
      const matches = curr.match(/^CognitoIdentityServiceProvider.(.*).LastAuthUser/)
      if (matches) {
        const cognitoId = localStorage.getItem(curr)
        const userData =
          JSON.parse(localStorage.getItem(`CognitoIdentityServiceProvider.${matches[1]}.${cognitoId}.userData`)) || {}
        const anonId = ((userData.UserAttributes || []).find((attr) => attr.Name === 'custom:anonId') || {}).Value

        acc.id = cognitoId
        acc.anonId = anonId
        acc.userData = userData
      }
      return acc
    },
    {
      id: '',
      anonId: '',
      userData: {},
    },
  )
}

/**
 * Automatically sync values across domains
 */
export default async function syncRemoteValues(remoteStorage, instance) {
  if (!isBrowser || !remoteStorage) return
  const _localStorage = instance.storage

  // const initAnon = instance.user('anonId')
  // console.log('initAnon', initAnon)

  const persistedCognitoInfo = getPersistedCognitoInfo()
  const persistedUserId = _localStorage.getItem(USER_ID)
  const persistedAnonId = _localStorage.getItem(ANON_ID)
  logger('local persistedUserId', persistedUserId)
  logger('local persistedAnonId', persistedAnonId)

  /* ◘◘ Setup initial values ◘◘ */
  // No userId found but cognito value found, set it locally
  if (!persistedUserId && persistedCognitoInfo.id) {
    _localStorage.setItem(USER_ID, persistedCognitoInfo.id)
  }
  // No anonId found but cognito value found, set it locally
  if (!persistedAnonId && persistedCognitoInfo.anonId) {
    instance.setAnonymousId(persistedCognitoInfo.anonId)
    logger('called analytics.setAnonymousId')
  }

  const localUserId = persistedUserId || persistedCognitoInfo.id
  const localAnonId = persistedAnonId || persistedCognitoInfo.anonId
  logger('localUserId', localUserId)
  logger('localAnonId', localAnonId)

  // const user = instance.user()
  // logger('analytics.user', user)

  // Get values from vendia.net
  const remoteValues = await remoteStorage.getItem([
    ANON_ID,
    USER_ID,
    USER_TRAITS,
    ATTR_FIRST,
    ATTR_LATEST,
    ATTR_PREVIOUS,
  ])
  logger('remoteValues', remoteValues)

  // No local localAnonId && remote has one, use it
  if (!localAnonId && remoteValues[ANON_ID]) {
    _localStorage.setItem(ANON_ID, remoteValues[ANON_ID])
    logger(`Set "${ANON_ID}" to ${remoteValues[ANON_ID]}`)
    instance.setAnonymousId(remoteValues[ANON_ID])
    // const newId = instance.user('anonId')
    // console.log('newId', newId)
  }

  // No local userId && remote has one, use it
  if (!localUserId && remoteValues[USER_ID]) {
    logger(`Set "${USER_ID}" to ${remoteValues[USER_ID]}`)
    _localStorage.setItem(USER_ID, remoteValues[USER_ID])
  }

  /**
   * @TODO figure out ID collisions in app
   */

  /* ◘◘ AnonId Conflict resolution ◘◘ */
  // const hasBothAnonId = localAnonId && remoteValues[ANON_ID]
  // if (hasBothAnonId && (localAnonId !== remoteValues[ANON_ID])) {
  //   // Conflict resolution for user Id
  //   logger(`Conflict between "${ANON_ID}"`)
  //   logger(`local "${ANON_ID}"`, localUserId)
  //   logger(`remote "${ANON_ID}"`, remoteValues[ANON_ID])
  //   /* Set remote value locally */
  //   _localStorage.setItem(ANON_ID, remoteValues[ANON_ID])
  //   instance.setAnonymousId(remoteValues[ANON_ID])
  //   logger(`Set "${ANON_ID}" to ${remoteValues[ANON_ID]}`)
  //   // const newId = instance.user('anonId')
  //   // console.log('newId', newId)
  // }

  // /* ◘◘ User Id Conflict resolution ◘◘ */
  // const hasBothUserId = localUserId && remoteValues[USER_ID]
  // if (hasBothUserId && (localUserId !== remoteValues[USER_ID])) {
  //   // Conflict resolution for user Id
  //   logger(`Conflict between "${USER_ID}"`)
  //   logger(`local "${USER_ID}"`, localUserId)
  //   logger(`remote "${USER_ID}"`, remoteValues[USER_ID])
  //   /* Set remote value locally */
  //   _localStorage.setItem(USER_ID, remoteValues[USER_ID])
  //   logger(`Set "${USER_ID}" to ${remoteValues[USER_ID]}`)
  // }

  return remoteValues
}
