import Auth from '@aws-amplify/auth'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { authContext } from 'src/utils/auth/AuthContext'
import notify from 'src/utils/notify'

import Button from '../buttons/button'
import FieldSet from '../inputs/field-set'
import Input from '../inputs/input'
import Form from './form'

function ForceResetForm(props: { user: any }) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { setAuthStatus } = React.useContext(authContext)
  const passwordInput = useRef<any>(null)

  useEffect(() => {
    if (passwordInput && passwordInput.current?.textInput) {
      setTimeout(() => {
        window.location.hash = ''
        passwordInput.current.focus()
      }, 10)
    }
  }, [])

  async function handleForcedReset(e: SubmitEvent, data: any = {}) {
    e.preventDefault()

    if (!data.password) {
      notify.error('Please input a new password to continue')
      passwordInput.current?.focus()
      return
    }

    if (!data.confirm) {
      notify.error('Please confirm the new password to continue')
      return
    }

    if (data.password !== data.confirm) {
      notify.error('Password and confirm password fields do not match. Please update these fields')
      return
    }

    setIsLoading(true)

    try {
      await Auth.completeNewPassword(props.user, data.password)
      const currentSession = (await Auth.currentSession()) as any
      console.log('auth result handleForcedReset', currentSession)
      setAuthStatus({
        userId: currentSession.idToken.payload.sub,
        idToken: currentSession.idToken.jwtToken,
        authenticated: true,
      } as any)
      // Redirect to dashboard
      navigate('/')
    } catch (completeNewPasswordError) {
      console.log('Force Reset Error', completeNewPasswordError)
      notify.error('Error Resetting password. Please contact support')
      setIsLoading(false)
    }
  }

  return (
    <div>
      <p>A reset of your password is required.</p>
      <p>Please enter a new password to continue</p>
      <Form onSubmit={handleForcedReset}>
        <FieldSet>
          <label htmlFor='password'>New Password</label>
          <Input ref={passwordInput as any} type='password' name='password' placeholder='Enter password' />
        </FieldSet>
        <FieldSet>
          <label htmlFor='confirm'>Confirm Password</label>
          <Input type='password' name='confirm' placeholder='Verify password' />
        </FieldSet>
        <Button
          icon={isLoading ? 'refresh' : null}
          type='submit'
          iconProps={{
            isSpinning: isLoading,
            size: 16,
          }}
        >
          {isLoading ? 'Confirming...' : 'Confirm new password'}
        </Button>
      </Form>
    </div>
  )
}

export default ForceResetForm
