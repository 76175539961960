/*
Automatically set event, category, and label for GA events
 */
export default function enrichGoogleAnalytics(pluginConfig = {}) {
  const defaultCategory = pluginConfig.defaultCategory
  return {
    name: 'google-analytics-enrich',
    // Enrich payload for google analytics plugin track
    'track:google-analytics': ({ payload }) => {
      const matches = payload.event.match(/([a-zA-Z]+):([a-zA-Z]+)(_([a-zA-Z]+$))?/) || []
      const [, _context, object, _rawAction, action] = matches
      // Format
      const finalObject = !action && defaultCategory ? defaultCategory : object
      const { label, title, details } = payload.properties
      return {
        ...payload,
        ...{
          properties: {
            ...payload.properties,
            ...{
              // GA Event Category
              category: payload.properties.category || finalObject,
              label: label || title || details || 'NA',
            },
          },
        },
      }
    },
  }
}
