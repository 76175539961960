export enum CreateFlow {
  SELECT_TYPE = 'select-type',
  STANDARD = 'standard',
  ENTITLEMENTS = 'entitlements',
}

export enum StepId {
  SelectUniType = 'select_uni_type',
  CreateNetwork = 'create_network',
  CreateLakehouse = 'create_lakehouse',
  ChooseSource = 'choose_source',
  ConnectSnowflake = 'connect_snowflake',
  ConnectCloudera = 'connect_cloudera',
  SelectSnowflakeTable = 'select_snowflake_table',
  DataProducts = 'data_products',
  SharingPermissions = 'sharing_permissions',
  AddNodes = 'add_nodes',
  SchemaEditMode = 'schema_edit_mode',
  SchemaDesigner = 'schema_designer',
  InvitePartners = 'invite_partners',
}

export const SOURCE_NODE_NAME = 'source-node'

export const DEFAULT_REGION = 'us-west-2'
