import { useMemo } from 'react'
import { Control } from 'react-hook-form'
import RadioGroupComponent from 'src/components/inputs/radio-group'
import { useGetOrg } from 'src/utils/hooks/use-get-org'

import { logger } from '../org-invite'
import { getRoleTemplates } from './role-templates'

type RoleTemplateRadioGroupProps = {
  name: string
  control: Control<any>
  label: string | JSX.Element
  labelDescription?: string | JSX.Element
}
export const RoleTemplateRadioGroup = ({ name, control, label, labelDescription }: RoleTemplateRadioGroupProps) => {
  const getOrg = useGetOrg()
  const org = getOrg?.data?.getOrganization

  // Get all the role templates
  const roleTemplates = useMemo(() => {
    if (!org?.orgId || !org?.domains) return []
    const templates = getRoleTemplates({ orgId: org?.orgId, orgDomains: org?.domains })
    logger('roleTemplates', templates)
    return templates
  }, [org?.orgId, org?.domains])

  // Map the role templates to the options for the radio group
  const roleOptions = roleTemplates.map((t) => ({
    value: t.name,
    label: t.name,
    description: t.description,
  }))
  return (
    <RadioGroupComponent
      label={label}
      labelDescription={labelDescription}
      name={name}
      control={control}
      rules={{ required: 'This field is required' }}
      options={roleOptions}
    />
  )
}
