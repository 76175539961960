import {
  DataPolicyColumnEffect,
  DataPolicyOperator,
  DataPolicyRowEffect,
  DataProductColumnPolicyInput,
  DataProductRowPolicyInput,
  LakehouseDataProductInput,
  ShareApp,
  Uni,
} from '@vendia/management-api-types'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'src/components/buttons/button'
import { ButtonIconAction } from 'src/components/buttons/button-icon-action'
import Card, { CardRule } from 'src/components/containers/card'
import { ContentWindow } from 'src/components/containers/content-window'
import ListboxInput, { ListOption } from 'src/components/inputs/listbox-input'
import BasicModal from 'src/components/modals/modal.basic'
import PageHeader from 'src/components/page-header'
import StatusPill from 'src/components/pills/status-pill'
import { SOURCE_NODE_NAME } from 'src/pages/uni-create/config'
import { parsetLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { ShareAppName } from 'src/types/types'
import { assert } from 'src/utils/assert'
import { AWS_REGIONS } from 'src/utils/csp/regions'
import { dataProductOperatorOptions, getColumnPermissionOptions } from 'src/utils/lakehouse/flows'

import { LakehouseDataLoader } from './lakehouse-data-loader'

enum Status {
  Idle,
  ShareModalOpen,
}

export const PageLakehouseOverview = () => <LakehouseDataLoader Comp={PageLakehouseOverviewContent} />

const PageLakehouseOverviewContent = ({ uni, shareApps }: { uni: Uni; shareApps: ShareApp[] }) => {
  const navigate = useNavigate()
  const uniAlias = uni.alias
  const ingestionShareApp = shareApps.find(
    (shareApp) =>
      shareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion ||
      shareApp.shareAppName === 'lakehouse-cloudera-ingestion',
  )
  assert(ingestionShareApp, 'Lakehouse share app not found')
  const shareAppConfig = useMemo(
    () => parsetLakehouseShareAppConfig(ingestionShareApp.shareAppConfig),
    [ingestionShareApp.shareAppConfig],
  )
  // Single source for now & single table for now!
  const sourceConfig = shareAppConfig.sources[0]
  const sourceTableName = sourceConfig.tableDefinitions[0].tableName

  const sourceNode = uni.nodes?.find((node) => node.name === SOURCE_NODE_NAME)
  const sourceNodeRegion = sourceNode?.region
  const totalRegions = new Set(uni.nodes?.map((node) => node.region)).size

  const [status, setStatus] = useState(Status.Idle)
  const [sharingDataProductIndex, setSharingDataProductIndex] = useState<number | null>(null)
  const [sharingDataProductRegion, setSharingDataProductRegion] = useState<string | null>(null)
  const setShareDataProduct = (index: number) => {
    setSharingDataProductIndex(index)
    const product = shareAppConfig.products[index]
    const region = product.regions[0]
    setSharingDataProductRegion(region)
    setStatus(Status.ShareModalOpen)
  }
  const closeShareDataProduct = () => {
    setStatus(Status.Idle)
    setSharingDataProductIndex(null)
    setSharingDataProductRegion(null)
  }
  const sharingDataProduct = sharingDataProductIndex !== null ? shareAppConfig.products[sharingDataProductIndex] : null
  return (
    <ContentWindow>
      <div className='flex flex-grow flex-col gap-4'>
        <PageHeader
          title={uniAlias}
          titleAfter={
            uni.status && uni.status !== 'RUNNING' && <StatusPill status={uni.status} data-testid='uni-status' />
          }
          actions={
            <Button kind='secondary' icon='gears' iconSize={18} to='../edit-products'>
              Manage products
            </Button>
          }
          testid='uni-name-header'
        />
        <div className='flex gap-6 p-6'>
          <div className='w-1/3'>
            <Card className='w-full'>
              <div className='flex flex-col gap-4'>
                <div className='flex justify-between pb-2 text-sm font-bold'>
                  <div>Source</div>
                  <div>{sourceNodeRegion}</div>
                </div>
                <CardRule />
                <div className='flex flex-col gap-2 pb-2'>
                  <div className='flex flex-col gap-4'>
                    <div className='flex items-center justify-between'>
                      <SectionHeading>Data source</SectionHeading>
                      <div>
                        <ButtonIconAction
                          icon='pencil'
                          label='Update connection'
                          onClick={() => navigate('../edit-connection')}
                        />
                      </div>
                    </div>
                    {ingestionShareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion && (
                      <div className='flex items-center gap-4'>
                        <img src='/images/vendor/snowflake.png' alt='Snowflake logo' className='size-10' />
                        <div className='flex flex-col gap-0.5'>
                          <div className='font-bold'>Snowflake</div>
                          <div className='text-neutral-8 text-xs'>{sourceTableName}</div>
                        </div>
                      </div>
                    )}
                    {ingestionShareApp.shareAppName === 'lakehouse-cloudera-ingestion' && (
                      <div className='flex items-center gap-4'>
                        <img src='/images/vendor/cloudera.png' alt='Cloudera logo' className='size-10' />
                        <div className='flex flex-col gap-0.5'>
                          <div className='font-bold'>Cloudera</div>
                          <div className='text-neutral-8 text-xs'>{sourceTableName}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <hr className='border-neutral-2 my-4 border' />
                  <div className='flex flex-col gap-4'>
                    <SectionHeading>Total data products</SectionHeading>
                    <BigMetric>{shareAppConfig.products.length}</BigMetric>
                  </div>
                  <hr className='border-neutral-2 my-4 border' />
                  <div className='flex flex-col gap-4'>
                    <SectionHeading>Total regions</SectionHeading>
                    <BigMetric>{totalRegions}</BigMetric>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className='flex w-2/3 flex-col gap-4'>
            {shareAppConfig.products.map((product, index) => (
              <DataProductCard
                key={product.key}
                product={product}
                productIndex={index}
                setShareDataProduct={setShareDataProduct}
              />
            ))}
            <div>
              <Button kind='secondary' icon='plus-m' iconSize={14} to='../edit-products'>
                Add a data product
              </Button>
            </div>
          </div>
        </div>
      </div>
      <BasicModal
        className='!max-w-2xl lg:!max-w-3xl'
        title='Share data product'
        isOpen={status === Status.ShareModalOpen}
        onClose={closeShareDataProduct}
        buttons={
          <div className='flex gap-4'>
            <Button kind='secondary' onClick={closeShareDataProduct}>
              Cancel
            </Button>
            <Button kind='primary'>Copy code</Button>
          </div>
        }
      >
        <div className='flex-1 space-y-4 overflow-auto p-4'>
          <div className='flex max-w-96 flex-col gap-3'>
            {sharingDataProduct ? (
              <ListboxInput
                className='min-w-72'
                name={`selectedProduct`}
                nestedLabel='Select a product'
                listOptions={shareAppConfig.products.map((product) => ({
                  value: product.key,
                  label: product.name,
                }))}
                value={sharingDataProduct.key}
                onChangeHandler={(option) => {
                  const selectedIndex = shareAppConfig.products.findIndex((p) => p.key === option.value)
                  const selectedProduct = shareAppConfig.products[selectedIndex]
                  setSharingDataProductIndex(selectedIndex)
                  setSharingDataProductRegion(selectedProduct.regions[0])
                }}
              />
            ) : null}
            {sharingDataProductRegion ? (
              <ListboxInput
                className='min-w-72'
                name={`selectedRegion`}
                nestedLabel='Select a region'
                listOptions={
                  AWS_REGIONS.map(({ value, displayAlt }) => ({
                    value,
                    label: displayAlt,
                    disabled: !sharingDataProduct?.regions.includes(value),
                    description: sharingDataProduct?.regions.includes(value)
                      ? null
                      : 'Product is not enabled for this region.',
                  })).sort((a, b) => {
                    if (a.disabled && !b.disabled) {
                      return 1
                    }
                    if (!a.disabled && b.disabled) {
                      return -1
                    }
                    return 0
                  }) as ListOption[]
                }
                value={sharingDataProductRegion}
                onChangeHandler={(option) => {
                  setSharingDataProductRegion(option.value)
                }}
              />
            ) : null}
          </div>
          <hr className='border-neutral-2 my-4 border' />
          <p>
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet
          </p>
        </div>
      </BasicModal>
    </ContentWindow>
  )
}

const DataProductCard = ({
  product,
  productIndex,
  setShareDataProduct,
}: {
  product: LakehouseDataProductInput
  productIndex: number
  setShareDataProduct: (index: number) => void
}) => {
  const [expanded, setExpanded] = useState(false)
  const tableDefinition = product.tableDefinitions?.[0]
  const rowPolicies = tableDefinition?.rowPolicies as DataProductRowPolicyInput[]
  const columnPolicies = tableDefinition?.columnPolicies as DataProductColumnPolicyInput[]
  const defaultColumnPolicyEffect = (tableDefinition as any)?.defaultColumnPolicyEffect
  const allAllowed =
    columnPolicies?.every((p) => p.effect === DataPolicyColumnEffect.Allow) ||
    (columnPolicies.length === 0 && defaultColumnPolicyEffect === DataPolicyColumnEffect.Allow)
  const allExcluded = columnPolicies?.every((p) => p.effect === DataPolicyColumnEffect.Exclude)
  const navigate = useNavigate()

  return (
    <Card key={product.key} className='w-full' padding='none'>
      <div className='flex w-full justify-between gap-6 p-6'>
        <div className='flex flex-grow flex-col gap-4'>
          <div className='flex items-center gap-4'>
            <img src='/images/data-product.svg' alt='Data set illustration' className='size-10' />
            <div className='flex flex-col gap-0.5'>
              <div className='text-lg font-bold'>{product.name}</div>
              <div className='text-neutral-7 text-balance text-xs'>
                {product.regions
                  .map((r) => AWS_REGIONS.find((region) => region.value === r)?.displayAlt || r)
                  .join(', ')}
              </div>
            </div>
          </div>
        </div>
        <div className='flex gap-2'>
          <ButtonIconAction
            icon={'share'}
            label='Share data product'
            onClick={() => {
              setShareDataProduct(productIndex)
            }}
          />
          <ButtonIconAction
            icon={'search-updated'}
            label='Explore data product'
            onClick={() => navigate('explore-product-data')}
          />
          <ButtonIconAction
            icon={expanded ? 'chevron-up' : 'chevron-down'}
            label='Toggle sharing rule details'
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      </div>
      {expanded ? (
        <>
          <CardRule />
          <div className='bg-uibg-0 p-6'>
            <div className='flex flex-col gap-2'>
              <div>
                <div className='mb-4 flex items-center justify-between'>
                  <h1 className='font-bold'>Sharing rules applied</h1>
                  <div>
                    <ButtonIconAction
                      icon='pencil'
                      label='Update data products'
                      onClick={() => navigate('../edit-products')}
                    />
                  </div>
                </div>
                <div className='flex flex-col text-sm'>
                  {(allAllowed || allExcluded) && (
                    <div className='border-neutral-5 border border-b-0 bg-white p-4 last:border-b'>
                      <strong>All columns</strong> are <strong>{allAllowed ? 'enabled' : 'excluded'}</strong>
                    </div>
                  )}
                  <div>
                    <h2 className='p-2 font-semibold'>Column policies</h2>
                    <ConfiguredColumnPolicies
                      policies={columnPolicies}
                      hasRules={!allAllowed && !allExcluded}
                      defaultColumnPolicyEffect={defaultColumnPolicyEffect}
                    />
                  </div>
                  <div>
                    <h2 className='p-2 font-semibold'>Row policies</h2>
                    <ConfigureRowPolicies policies={rowPolicies} hasRules={!allAllowed && !allExcluded} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </Card>
  )
}

const getPolicyEffectLabel = (effect: any, defaultColumnPolicyEffect?: DataPolicyColumnEffect): string | undefined => {
  const options = getColumnPermissionOptions(defaultColumnPolicyEffect)
  return options.find((option) => option.value === effect)?.label as string
}

const getOperatorLabel = (operator?: DataPolicyOperator) =>
  dataProductOperatorOptions.find((option) => option.value === operator)?.label as string

const ConfiguredColumnPolicies = ({
  policies,
  hasRules,
  defaultColumnPolicyEffect,
}: {
  policies: DataProductColumnPolicyInput[]
  hasRules: boolean
  defaultColumnPolicyEffect: DataPolicyColumnEffect
}) => (
  <>
    <span className='ml-2'>
      All columns default to be{' '}
      <strong>{defaultColumnPolicyEffect === DataPolicyColumnEffect.Allow ? 'allowed' : 'excluded'}</strong>
    </span>
    <div className='mt-1 flex flex-col bg-white text-sm'>
      {!hasRules && <h6 className='text-l font-semibold'>No rules</h6>}
      {hasRules &&
        policies
          // Don't include rules that match the default policy
          .filter((p) => p.effect !== defaultColumnPolicyEffect)
          .map((columnPolicy) => {
            return (
              <div className='border-neutral-5 border border-b-0 p-4 last:border-b' key={columnPolicy.name}>
                When <strong>{columnPolicy.name}</strong> is present,{' '}
                <strong>{getPolicyEffectLabel(columnPolicy.effect, defaultColumnPolicyEffect)?.toLowerCase()}</strong>
                {columnPolicy.value ? (
                  <>
                    {' '}
                    <em className='font-semibold underline'>{columnPolicy.value}</em>
                  </>
                ) : null}
                {columnPolicy.value && columnPolicy.effect === DataPolicyColumnEffect.MaskExceptLast
                  ? ' characters'
                  : null}
                .
              </div>
            )
          })}
    </div>
  </>
)

const ConfigureRowPolicies = ({
  policies,
  hasRules,
}: {
  policies: DataProductRowPolicyInput[]
  hasRules?: boolean
}) => (
  <div className='flex flex-col bg-white text-sm'>
    {!hasRules && <h6 className='text-l font-semibold'>No rules</h6>}
    {hasRules &&
      policies?.map((rowPolicy) => (
        <div className='border-neutral-5 border border-b-0 p-4 last:border-b' key={rowPolicy.name}>
          <strong>{getPolicyEffectLabel(rowPolicy.effect)}</strong>
          {' data, where '}
          <strong>{rowPolicy.name}</strong>
          {' is '}
          <strong>{getOperatorLabel(rowPolicy.operator!)?.toLowerCase()}</strong>
          {rowPolicy.value}.
        </div>
      ))}
  </div>
)

const SectionHeading = ({ children }: { children: React.ReactNode }) => (
  <h5 className='text-neutral-9 text-xs font-bold'>{children}</h5>
)

const BigMetric = ({ children }: { children: React.ReactNode }) => <div className='text-2xl font-bold'>{children}</div>

// const api = useApi()
// const getTableSchemaMutation = useMutation(
//   async ({ uniName, nodeName, tableName }: any) =>
//     api.getSourceTableSchema<{ getSourceTableSchema?: SnowflakeTableColumn[] }>({ uniName, nodeName, tableName }),
//   {
//     onError: (error) => notify.error(`Error fetching Snowflake table schema: ${error}`),
//     onSuccess: (response) => {
//       console.log('response', response) // TOOD: replace with logger
//       if (response.message) {
//         notify.error(`Error fetching Snowflake table schema: ${response.message}`)
//         return
//       }
//       if (response.errors) {
//         notify.error(`${response.errors[0].message}`)
//         return
//       }
//       if (!response.getSourceTableSchema) {
//         notify.error('Error fetching Snowflake table schema')
//         return
//       }
//       notify.success(response.getSourceTableSchema)
//     },
//   },
// )
