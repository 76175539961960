import { DataPolicyColumnEffect } from '@vendia/management-api-types'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { StepId } from 'src/pages/uni-view/lakehouse/config'
import { LakehouseEditStepValues } from 'src/types/lakehouse'
import { AWS_REGIONS } from 'src/utils/csp/regions'

import Button from '../buttons/button'
import { StepComponent } from '../flows/multi-step-flow'
import Input from '../inputs/input.hookform'
import ListboxInput from '../inputs/listbox-input'
import Loader from '../loaders/page-loader'
import HoverableTooltip from '../messages/hoverable-tooltip'

export const EditDataProducts: StepComponent<LakehouseEditStepValues> = ({
  register,
  setValue,
  setStepValues,
  stepValues,
  submitStep,
  setCurrStepId,
  currStepId,
  control,
  formState: { errors },
  setError,
  trigger,
  currStepIndex,
}) => {
  const name = 'products'

  const { fields, append, remove } = useFieldArray({
    name,
    control,
    rules: { minLength: 1 },
  })
  const productValues = useWatch({ name, control })

  function addProduct() {
    append(
      {
        name: '',
        regions: [],
        columnPolicies: [],
        rowPolicies: [],
        defaultColumnPolicyEffect: DataPolicyColumnEffect.Exclude,
      },
      { shouldFocus: true, focusName: 'name' },
    )
    setStepValues({
      ...stepValues,
      products: productValues,
    })
  }

  useEffect(() => {
    if (fields.length === 0) {
      addProduct()
    }
  }, [])

  useEffect(() => {
    submitStep({ nextStepIndex: currStepIndex })
  }, [productValues])

  function validConfiguration(product: any) {
    if (product?.defaultColumnPolicyEffect === DataPolicyColumnEffect.Exclude && product?.columnPolicies.length === 0) {
      return 'Current configuration would return no data'
    }
  }

  if (fields.length === 0) {
    return (
      <div className='grid place-items-center'>
        <Loader />
      </div>
    )
  }

  return (
    <div className='flex w-full flex-1 flex-col p-4 lg:px-8'>
      {fields.length > 0 && (
        <div
          className={clsx(
            'flex h-5/6 w-full max-w-7xl flex-col justify-center gap-4',
            // h.scroll entire rows area on smaller screens
            'min-w-[1100px]',
          )}
        >
          {fields.map((field, index) => {
            const prefix = `${name}.${index}`
            const product = productValues?.[index]
            const hasNameAndRegionSelected = product?.name !== '' && product?.regions.length > 0

            return (
              <div
                key={field.id}
                className={'bg-uibg-1 border border-indigo-300 px-8 py-6'}
                data-testid={`data-product-${index}`}
              >
                <div className='flex items-center justify-between gap-4'>
                  <div className='flex items-start gap-4'>
                    <Input
                      className='min-w-72'
                      name={`${prefix}.name`}
                      nestedLabel='Name your data product'
                      register={register}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'This field is required.',
                        },
                      }}
                      errors={errors}
                      data-testid={'product-name-input'}
                    />
                    <ListboxInput
                      multiple
                      className='min-w-72'
                      name={`${prefix}.regions`}
                      nestedLabel='Make data product available in'
                      listOptions={AWS_REGIONS.map(({ value, display }) => ({
                        value,
                        label: display,
                      }))}
                      rules={{ required: 'This field is required' }}
                      control={control}
                      data-testid={'product-region-select'}
                    />
                  </div>
                  <div className='flex items-center gap-2'>
                    <HoverableTooltip
                      asChild={true}
                      sideOffset={5}
                      disabled={hasNameAndRegionSelected}
                      content={
                        'Please provide a name and select at least one region to enable sharing permissions configuration.'
                      }
                    >
                      <div className='flex w-64 flex-col'>
                        <Button
                          disabled={!hasNameAndRegionSelected}
                          kind='secondary'
                          onClick={async () => {
                            setStepValues({
                              ...stepValues,
                              sharingPermissionsEditIndex: index,
                              products: productValues,
                            })
                            setCurrStepId(StepId.SharingPermissions)
                          }}
                          className='w-64'
                        >
                          Configure Sharing Permissions
                        </Button>

                        {validConfiguration(product) && (
                          <div className='flex gap-1 text-balance py-1 text-left text-xs'>
                            {/* <Icon name='error' size='xs' className='' /> */}
                            {validConfiguration(product)}
                          </div>
                        )}
                      </div>
                    </HoverableTooltip>
                    {productValues?.length > 1 && (
                      <div className={'px-2'}>
                        <Button kind='link' icon='trash' onClick={() => remove(index)} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
          <div className='-mt-4 flex w-full max-w-4xl pb-6'>
            <Button
              kind={'secondary'}
              style={{ marginTop: 20 }}
              onClick={(e) => {
                e.preventDefault()
                addProduct()
              }}
              icon='plus-m'
              iconSize={14}
            >
              Add another
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
