import React, { useEffect, useState } from 'react'

export type DynamicInputProps = {
  /** Input name  */
  name: string
  /** Input value  */
  value?: string
  /** Async get input value function */
  getValue: () => Promise<string>
}

const DynamicInput = ({ getValue, value = '', name }: DynamicInputProps) => {
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    async function setValue() {
      const resolveValue = getValue ? await getValue() : value
      if (resolveValue) {
        setInputValue(resolveValue)
      }
    }
    setValue()
  }, [])

  /* Hide if no value */
  if (!inputValue) {
    return null
  }

  return <input name={name} value={inputValue} type='hidden' />
}

export default DynamicInput
