import { LakehouseDataProductInput, ShareApp, Uni } from '@vendia/management-api-types'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocalstorageState } from 'rooks'
import { ButtonIconAction } from 'src/components/buttons/button-icon-action'
import Card, { CardRule } from 'src/components/containers/card'
import { ContentWindow } from 'src/components/containers/content-window'
import ListboxInput from 'src/components/inputs/listbox-input'
import MonacoEditorInput from 'src/components/inputs/monaco-editor-input'
import PageLoader from 'src/components/loaders/page-loader'
import PageHeader from 'src/components/page-header'
import { BasicTable } from 'src/components/tables/basic-table'
import { LakehouseShareAppConfig, parsetLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { ShareAppName } from 'src/types/types'
import { assert } from 'src/utils/assert'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseDataLoader } from './lakehouse-data-loader'

enum State {
  INITIALIZED,
  FETCHING_DATA,
  DATA_FETCH_SUCCESSFUL,
  DATA_FETCH_FAILED,
}

// Both SQL safe and lowercase (for glue database/table names)
export function getSafeUniNodeName(uniName: string, nodeName: string) {
  return `${uniName}_${nodeName}`.replace(/-/g, '_').replace(/\./g, '_').toLowerCase()
}

export const PageLakehouseExplorer = () => <LakehouseDataLoader Comp={PageLakehouseExplorerContent} />

const PageLakehouseExplorerContent = ({ uni, shareApps }: { uni: Uni; shareApps: ShareApp[] }) => {
  const ingestionShareApp = shareApps.find(
    (shareApp) =>
      shareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion ||
      shareApp.shareAppName === 'lakehouse-cloudera-ingestion',
  )
  assert(ingestionShareApp, 'Lakehouse share app not found')
  const shareAppConfig = useMemo(
    () => parsetLakehouseShareAppConfig(ingestionShareApp.shareAppConfig),
    [ingestionShareApp.shareAppConfig],
  )

  const [data, setData] = useState([])
  const api = useApi()
  const [state, setState] = useState(State.INITIALIZED)
  const [expanded, setExpanded] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [sqlEditor, setSqlEditor] = useLocalstorageState('warehouse_sql_editor', '')
  const [selectedProduct, setSelectedProduct] = useState(shareAppConfig.products[0])
  const distributionNodes = uni.nodes?.filter((node) => node.name.includes('distribution'))
  const nodeName = distributionNodes?.find((node) => node.region === selectedProduct.regions[0])?.name
  const formMethods = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      sqlEditor:
        sqlEditor ||
        `

`,
    },
  })
  const { handleSubmit, control, setValue } = formMethods

  const buildSqlQuery = (
    uni: Uni,
    shareAppConfig: LakehouseShareAppConfig,
    selectedProduct: LakehouseDataProductInput,
  ) => {
    //iceberg_test_dataset_17_unis_jakepartusch_com_distribution_node_us_east_2_data_staging_wtvn96
    const prefix = 'iceberg'
    const sourceTable = shareAppConfig.sources[0].tableDefinitions[0].tableName
    const productKey = selectedProduct.key
    const safeUniNodeName = getSafeUniNodeName(uni.name, nodeName!)
    const icebergTable = `${prefix}_${safeUniNodeName}_${sourceTable}_${productKey}`.toLowerCase()
    return `SELECT * FROM ${icebergTable} limit 10;`
  }

  const onSubmit = async (formValues: { sqlEditor: string }) => {
    setState(State.FETCHING_DATA)
    setSqlEditor(formValues.sqlEditor)
    setValue('sqlEditor', formValues.sqlEditor)
    const sqlStatement = formValues.sqlEditor?.trim()
    const response = await api.fetchLakehouseData({
      uniName: uni.name,
      nodeName: nodeName,
      dataProductKey: selectedProduct.key,
      sqlText: sqlStatement,
    })
    if (response.errors?.length) {
      setErrorMessage(response.errors[0].message)
      setState(State.DATA_FETCH_FAILED)
      return
    }
    setData(response.fetchLakehouseData.results)
    setState(State.DATA_FETCH_SUCCESSFUL)
  }

  const columnHeaders = Object.keys(data?.[0] ?? {})
  const rows =
    data?.map((row) => {
      return Object.values(row)
    }) ?? []

  useEffect(() => {
    const sqlQuery = buildSqlQuery(uni, shareAppConfig, selectedProduct)
    setSqlEditor(sqlQuery)
    onSubmit({ sqlEditor: sqlQuery })
  }, [])

  return (
    <ContentWindow>
      <div className='flex flex-grow flex-col gap-4 pt-4'>
        <PageHeader title={'Query & Explore'} testid='uni-name-header' />
        <div className='flex flex-col gap-6 p-6'>
          <Card className='w-full' padding='none' overflow='visible'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-col gap-4'>
                <div className='flex items-center justify-between p-6 text-sm font-bold'>
                  <div className='flex items-center gap-2'>
                    <ListboxInput
                      className='min-w-72'
                      name={`selectedProduct`}
                      nestedLabel='Select a product'
                      listOptions={shareAppConfig.products.map((product) => ({
                        value: product.key,
                        label: product.name,
                      }))}
                      value={selectedProduct.key}
                      onChangeHandler={(option) => {
                        const selectedProduct = shareAppConfig.products.find((product) => product.key === option.value)
                        if (!selectedProduct) {
                          return
                        }
                        const sqlQuery = buildSqlQuery(uni, shareAppConfig, selectedProduct)
                        setSelectedProduct(selectedProduct)
                        setSqlEditor(sqlQuery)
                        setExpanded(false)
                        onSubmit({ sqlEditor: sqlQuery })
                      }}
                    />
                  </div>
                  <div className='flex gap-2'>
                    <ButtonIconAction icon='play' label='Run sql query' type='submit' />
                    <ButtonIconAction
                      type='button'
                      icon={expanded ? 'chevron-up' : 'chevron-down'}
                      label='Toggle sharing rule details'
                      onClick={() => setExpanded(!expanded)}
                    />
                  </div>
                </div>
                {expanded && (
                  <div>
                    <CardRule />
                    <div className='bg-uibg-0 p-6'>
                      <div className='grid'>
                        <MonacoEditorInput
                          className='border-uibg-4 bg-white'
                          language='sql'
                          id='sqlEditor'
                          name='sqlEditor'
                          control={control}
                          minHeight={200}
                          rules={{ required: 'This field is required' }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </Card>
          <Card className='w-full' padding='none'>
            <div className='flex flex-col gap-4'>
              {state === State.INITIALIZED && <div className='p-6'>Run a query to see results</div>}
              {state === State.FETCHING_DATA && <PageLoader />}
              {state === State.DATA_FETCH_SUCCESSFUL && (
                <div>
                  {/* @ts-ignore rows is fine */}
                  <BasicTable columnHeaders={columnHeaders} rows={rows} />
                </div>
              )}
              {state === State.DATA_FETCH_FAILED && <div className='p-6'>{errorMessage}</div>}
            </div>
          </Card>
        </div>
      </div>
    </ContentWindow>
  )
}
