// @ts-strict-ignore
import { useEffect, useRef } from 'react'

export function useDocumentTitle(title, retainOnUnmount = false) {
  const defaultTitle = useRef(document.title)

  useEffect(() => {
    setTitle(title)
  }, [title])

  useEffect(() => {
    return () => {
      if (!retainOnUnmount) {
        setTitle(defaultTitle.current)
      }
    }
  }, [])
}

export function setTitle(title) {
  document.title = title
}

export const POSTFIX = 'Vendia Share'
